import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { BtnVolverInicio } from "../boton/Boton-secundario";

function SubOservicios() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
            <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu BtnMovil">Servidores</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/otro-servicio"
                aria-label="servidores web"
                title="servidores web"
              >
                Descripción
              </NavLink>
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/precio-servidor"
                aria-label="precios servidores web"
                title="precios servidores web"
              >
                Precio
              </NavLink>
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/especificaciones-otros-servivio"
                aria-label="Especificaciones servidores web"
                title="Especificaciones servidores web"
              >
                Especificaciones
              </NavLink>
              <a
                        title="cotizar web"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault BtnMovil"
            href="https://penseo.odoo.com/contacto">
            Cotizar
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default SubOservicios;
