import "../index.css";
import Bannerm from "../componentes/BannerPaginas/Banner-M";
import SubMantenimineto from "../componentes/submenus/SubMantenimineto";
import BloqueMante from "../componentes/Bloques/Detalles-mante";
import SubFooter from "../componentes/submenus/SubFooter";
import { AyudaMantenimiento } from "../componentes/Bloques/Note";
import SubOferta from "../componentes/submenus/SubOferta";
import React from 'react';
import Helmet from "react-helmet";
function Mantenimiento() {
  return (
    <>
    <Helmet>
      <title>Servicios de mantenimiento web antofagasta - Penseo.cl</title>
      <meta
          name="description"
          content="Mantenimiento Web es un servicio que se encarga de mantener el buen funcionamiento de un sitio web. Esto incluye realizar actualizaciones y mejoras, comprobar la seguridad y velocidad del sitio, realizar pruebas de rendimiento, así como realizar copias de seguridad."
        />
    </Helmet>
      <SubMantenimineto />
      <Bannerm />
      <BloqueMante />
      <SubOferta/>
      <AyudaMantenimiento/>
      <SubFooter />
    </>
  );
}
export default Mantenimiento;
