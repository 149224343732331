import BannermHome from "../componentes/Banner/Banner-Home";
import { Footernote } from "../componentes/Bloques/Note";
import ContainerServicios from "../componentes/Bloques/Servicios";
import React from 'react';
import Helmet from "react-helmet";
function inicio() {
  return (
    <>
      <Helmet>
        <title>Creación de página web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Empresa Antofagastina de servicios página y tiendas online y Seo. servidores web seguro y escalable garantizan la mejor experiencia para tus clientes."
        />
      </Helmet>
      <BannermHome />
      <ContainerServicios />
      <Footernote />
    </>
  );
}
export default inicio;
