import "../../styles/planes.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dino1 from "../../video/11.mp4";
import dino2 from "../../video/12.mp4";
import dino3 from "../../video/13.mp4";
import No from "../../svg/x.svg";
import Si from "../../svg/chek.svg";
import { SliderHerramientaweb } from "../Bloques/slider";


function DetallePaginaWeb() {
  return (
    <section>
      <Container className="bloquedetalle">
        <Row>
        <Col>
          <div class="table-responsive">
            <table>
              <thead>
                <tr>
                  <th className="columna">Características</th>
                  <th>
                    <div className="boxvideo">
                      <video
                        className="video2"
                        src={dino1}
                        loop
                        muted
                      />
                    </div>
                    Estándar
                    <sup>
                      <a
                        aria-label="Nota 2 - Plan estandar"
                        className="footnote"
                        href="#footnote2"
                        title="página web estandar"
                      >
                        2
                      </a>
                    </sup>
                  </th>
                  <th>
                    <div className="boxvideo">
                      <video
                        className="video2"
                        src={dino2}
                        loop
                        muted
                      />
                    </div>
                    Plus
                    <sup>
                      <a
                        aria-label="Nota 1 - Plan plus"
                        className="footnote"
                        href="#footnote3"
                        title="página web plus"
                      >
                        3
                      </a>
                    </sup>
                  </th>
                  <th>
                    <div className="boxvideo">
                      <video
                        className="video2"
                        src={dino3}
                        loop
                        muted
                      />
                    </div>
                    Avanzado
                    <sup>
                      <a
                        aria-label="Nota 4 - Plan Avanzado"
                        className="footnote"
                        href="#footnote4"
                        title="página web avanzada"
                      >
                        4
                      </a>
                    </sup>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="linea">
                  <td className="nombrePlantabla">
                    Páginas Internas
                    <sup>
                      <a
                        aria-label="Nota 5 - Paginas interna"
                        className="footnote"
                        href="#footnote5"
                        title="Páginas interna"
                      >
                        5
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">6</td>
                  <td className="boxdatos fondotabla">10</td>
                  <td className="boxdatos">12</td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Tipo de páginas
                    <sup>
                      <a
                        aria-label="Nota 6 - Tipo de página web"
                        className="footnote"
                        href="#footnote6"
                        title="Tipo de página web"
                      >
                        6
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">WordPress</td>
                  <td className="boxdatos fondotabla">WordPress</td>
                  <td className="boxdatos">WordPress</td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Seo
                    <sup>
                      <a
                        aria-label="Nota 7 - que es seo"
                        className="footnote"
                        href="#footnote7"
                        title="Que es seo"
                      >
                        7
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos fondotabla">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos">
                  <img src={Si} alt="Svg Si" title="icono si"/>
                  </td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Diseño UI
                    <sup>
                      <a
                        aria-label="Nota 8 - Que es diseño ui"
                        className="footnote"
                        href="#footnote8"
                        title="Que es diseño ui"
                      >
                        8
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos fondotabla">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos">
                  <img src={Si} alt="Svg Si" title="icono si"/>
                  </td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Accesibilidad
                    <sup>
                      <a
                        aria-label="Nota 9 - Que es accesibilidad web"
                        className="footnote"
                        href="#footnote9"
                        title="Que es accesibilidad web"
                      >
                        9
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos fondotabla">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos">
                  <img src={Si} alt="Svg Si" title="icono si" />
                  </td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Animaciones
                    <sup>
                      <a
                        aria-label="Nota 10 - Que son las aniaciones web"
                        className="footnote"
                        href="#footnote10"
                        title="Que son las aniaciones web"
                      >
                        10
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos fondotabla">
                    <img src={No} alt="Svg No" title="icono no"/>
                  </td>
                  <td className="boxdatos">
                  <img src={Si} alt="Svg Si" title="icono si"/>
                  </td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                    Garantia
                    <sup>
                      <a
                        aria-label="Nota 11 - Tiempo de garantia"
                        className="footnote"
                        href="#footnote11"
                        title="Tiempo de garantia"
                      >
                        11
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos fondotabla">1 Meses</td>
                  <td className="boxdatos fondotabla1">2 Meses</td>
                  <td className="boxdatos fondotabla">3 Meses</td>
                </tr>
                <tr>
                  <td className="nombrePlantabla">
                  Plazo de Entrega - dias
                    <sup>
                      <a
                        aria-label="Nota 12 - Plazos de entrega"
                        className="footnote"
                        href="#footnote12"
                        title="Plazos de entrega"
                      >
                        12
                      </a>
                    </sup>
                  </td>
                  <td className="boxdatos">4</td>
                  <td className="boxdatos fondotabla">8</td>
                  <td className="boxdatos">15</td>
                </tr>
                <tr className="linea2">
                  <td className="nombrePlantabla">Precio</td>
                  <td className="boxdatos">$399.000</td>
                  <td className="boxdatos fondotabla">$599.000</td>
                  <td className="boxdatos">$699.000</td>
                </tr>
              </tbody>
            </table>
          </div>
        <p>*IMPORTANTE: Ninguno de nuestros planes considera la creación ni edición de contenidos.</p>
        </Col>
        <Col>
          <SliderHerramientaweb/>
        </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DetallePaginaWeb;
