import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BtnVolverInicio } from "../boton/Boton-secundario";

function SubAyuda() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
          <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu">Ayuda</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <a
            title="cotizar web"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault"
            href="https://penseo.odoo.com/contacto">
            Cotizar
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default SubAyuda;
