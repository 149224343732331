import SubPweb from "../../componentes/submenus/SubPweb";
import ListaServicios from "../../componentes/Bloques/ListaServicios";
import DetallePaginaWeb from "../../componentes/Planes/DetallePaginaWeb";
import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import { NotePaginaWeb } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function EPweb() {
  return (
    <>
      <Helmet>
        <title>Especificaciones de página web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Conoce nuestros diferentes planes de pagina web, digitaliza tu empresa o servicio con sitio que ayudara a llegar a mas clientes"
        />
      </Helmet>
      <SubPweb />
      <DetallePaginaWeb />
      <ListaServicios />
      <Clientes />
      <NotePaginaWeb />
      <SubFooter />
    </>
  );
}
export default EPweb;
