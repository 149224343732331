import Paginaweb from "../../imagenes/paginawebhome.webp";
import "../../styles/banner.css";
import { BtnSecundarioPaginaWeb } from "../boton/Boton-secundario";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BannerpwHome() {
  return (
    <div className="cajahome">
      <picture>
        <LazyLoadImage
          className="imgSliderHome"
          src={Paginaweb}
          alt="personas trabajando en su negocio"
          title="Páginas web"
          effect='blur'
        />
      </picture>
      <div className="cajatexto">
        <div className="bloquesubtitulo">
          <h2 className="TituloSlider">Páginas Web</h2>
          <sup>
            <a
              aria-label="Nota 4 ¿qué es una página web?"
              className="footnote"
              href="#footnote4"
              title="ver mas sobre pagina web"
            >
              4
            </a>
          </sup>
        </div>
        <div className="DescripSlider">Haz realidad tus ideas</div>
        <span className="PrecioSlider">
          <span className="pesoshome">$</span>399.000
        </span>
        <div className="boxbotonsecundario">
          <BtnSecundarioPaginaWeb />
        </div>
      </div>
    </div>
  );
}
export default BannerpwHome;
