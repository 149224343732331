import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { BtnVolverInicio } from "../boton/Boton-secundario";


function SubTonine() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
          <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu BtnMovil">Tienda online</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/tienda-online"
                aria-label="tienda online"
                title="tienda online"
              >
                Descripción
              </NavLink>
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/precio-tienda-online"
                aria-label="precios tienda online"
                title="precios tienda online"
              >
                Precios
              </NavLink>
              <NavLink
                title="Especificaciones tienda online"
                className="BtnSubMenu BtnDefault"
                to="/especificaciones-tienda-online"
                aria-label="Especificaciones tienda online"
              >
                Especificaciones
              </NavLink>
              <a
            title="cotizar tienda online"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault BtnMovil"
            href="https://penseo.odoo.com/cotizar">
            Cotizar
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>

  );
}

export default SubTonine;
