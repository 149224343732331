import Soporte from "../../imagenes/mantenimiento-web.webp";
import "../../styles/banner.css";
import { BtnSecundarioMantenimiento } from "../boton/Boton-secundario";
import { LazyLoadImage } from "react-lazy-load-image-component";

function BannerHome() {
  return (
    <div className="cajahome">
      <picture>
        <LazyLoadImage
          className="imgSliderHome"
          src={Soporte}
          alt="Persona trabajando en su página web"
          title="Mantenimiento web"
          effect='blur'
        />
      </picture>
      <div className="cajatexto">
        <div className="bloquesubtitulo">
          <h2 className="TituloSlider">Mantenimiento</h2>
          <sup>
            <a
              aria-label="Nota 2 ¿qué es el mantenimiento web?"
              className="footnote"
              href="#footnote2"
              title="Ver mas sobre mantenimiento web"
            >
              2
            </a>
          </sup>
        </div>
        <div className="DescripSlider">Enfócate en tu negocio</div>
        <span className="PrecioSlider">
          <span className="pesoshome">$</span>55.900
        </span>
        <div className="boxbotonsecundario">
          <BtnSecundarioMantenimiento />
        </div>
      </div>
    </div>
  );
}
export default BannerHome;
