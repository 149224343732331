import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ListaServicios() {
  return (
    <section className="ColorGriss">
      <Container>
        <Row>
          <Col sm={12} xl={12}>
            <div className="BloqueCenter BloqueTitulo">
              <span className="SubTituloGeneral">
                Precios sencillos, económico y escalables.
              </span>
              <h2 className="TituloGeneral">
                Creación de cuentas e integraciones
              </h2>
              <p className="ParrafoGeneral">
                Saca el máximo provecho de tu pagina web con las diferentes
                herramientas
              </p>
            </div>
          </Col>
          <Col sm={12} xl={6}>
            <ListGroup as="ul">
              <ListGroup.Item as="li" active>
                Todos los planes incluyen
              </ListGroup.Item>
              <ListGroup.Item as="li">100% Autoadministrable</ListGroup.Item>
              <ListGroup.Item as="li">
                Ajuste perfecto a dispositivo móvil
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Posicionamiento SEO Básico
              </ListGroup.Item>
              <ListGroup.Item as="li">Formularios de Contacto</ListGroup.Item>
              <ListGroup.Item as="li">
                Links a tus Redes Sociales
              </ListGroup.Item>
              <ListGroup.Item as="li">Google reCAPTCHA</ListGroup.Item>
              <ListGroup.Item as="li">
                Integración Google Analytics
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Integración Google Search Console
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Certificado de seguridad SSL
              </ListGroup.Item>
              <ListGroup.Item as="li">Google Maps</ListGroup.Item>
              <ListGroup.Item as="li">Botón de Whatsapp</ListGroup.Item>
              <ListGroup.Item as="li">Página error 404</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} xl={6}>
            <ListGroup as="ul">
              <ListGroup.Item as="li" active>
                Servicos adicionales
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Chat online
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Integración Pixel Facebook
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Integración Mailer Lite Email
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Creación e integración Google ADS
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Servidor web
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Correo corporativo
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Repositorio de imágenes
              </ListGroup.Item>
              <ListGroup.Item as="li" disabled>
                Investigación de la competencia
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ListaServicios;
