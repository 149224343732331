import "../index.css";
import Vendelinea2 from "../componentes/Bloques/Vende-linea2";
import SubPweb from "../componentes/submenus/SubPweb";
import Bannerpw from "../componentes/BannerPaginas/Banner-pw";
import SubFooter from "../componentes/submenus/SubFooter";
import { AyudaPaginaWeb } from "../componentes/Bloques/Note";
import SubOferta from "../componentes/submenus/SubOferta";
import React from 'react';
import Helmet from "react-helmet";
function Pweb() {
  return (
    <>
          <Helmet>
          <title>Diseño de página web antofagasta - Penseo.cl</title>  
          <meta
          name="description"
          content="Diseño paǵina web profesional para ayudar a las empresas a aumentar el tráfico, mejorar la presencia en línea y generar leads. Nuestros servicios incluyen diseño, optimización para motores de búsqueda."
        />

    </Helmet>

      <SubPweb />
      <Bannerpw />
      <Vendelinea2 />
      <SubOferta/>
      <AyudaPaginaWeb/>
      <SubFooter />
    </>
  );
}
export default Pweb;
