import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BtnVolverInicio } from "../boton/Boton-secundario";

function SubCasosExito() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
        <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu">Casos de exito</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
          <a
          title="cotizar web"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault"
            href="https://penseo.odoo.com/contacto">
            Cotizar
          </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default SubCasosExito;
