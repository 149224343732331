import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import gsap from "gsap";
import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import estrella from "../../svg/estrella.svg";
import ufo from "../../svg/ufo.svg";

import estrella2 from "../../svg/estrella2.svg";
import planeta2 from "../../svg/planeta.svg";
import Planeta from "../../imagenes/planeta.webp";
import Planeta2 from "../../imagenes/planeta2.webp";
import { BtnContacto } from "../boton/Boton-secundario";
import { SliderServicios } from "../Bloques/slider";
import { Recursos } from "../Bloques/Titulos";


gsap.registerPlugin(ScrollTrigger);

function BannermHome() {
  useEffect(() => {
    gsap.from(".estrella", {
      scrollTrigger: {
        scrub: 0.1,
      },
      y: 150,
    });

    gsap.from(".planeta", {
      scrollTrigger: {
        scrub: 0.1,
      },
      y: 100,
      rotate: 90,
    });
    gsap.from(".planeta2", {
      scrollTrigger: {
        scrub: 0.1,
      },
      y: 150,
    });
    gsap.to(".botonefecto", {
      scrollTrigger: {
        scrub: 1,
      },
      zoom: 1.5,
    });
    gsap.to(".ufo", {
      scrollTrigger: {
        scrub: 1,
      },
      x: 500,
      y: -190,
      scale: 0.1,
    });
    ScrollTrigger.refresh();
  });

  return (
    <div>
      <section className="sectionhome">
        <Container>
          <Row>
            <Col sm={6} md={6} className="Bloquehomeslider">
                <span className="SubTituloGeneral">
                Creatividad y potencia
                </span>
                <h1 className="TituloGeneral">Una combinación única para tu página web o tienda online</h1>
                <div className="spaceboton">
                  <BtnContacto />
                </div>
            </Col>
            <Col sm={6} md={6} className="efecto">
              <img
                className="estrella10 estrella icono"
                src={estrella2}
                alt="Estrella1"
                title="Estrella1"
              />
              <img
                className="estrella11 estrella icono"
                src={estrella2}
                alt="Estrella2"
                title="Estrella2"
              />
              <img
                className="estrella12 estrella icono"
                src={estrella2}
                alt="Estrella3"
                title="Estrella3"
              />
              <img
                className="estrella13 estrella icono"
                src={estrella2}
                alt="Estrella4"
                title="Estrella4"
              />
              <img
                className="estrella14 estrella icono"
                src={estrella2}
                alt="Estrella5"
                title="Estrella5"
              />
              <img
                className="estrella15 estrella icono"
                src={estrella2}
                alt="Estrella6"
                title="Estrella6"
              />
              <img
                className="estrella16 estrella icono"
                src={estrella2}
                alt="Estrella7"
                title="Estrella6"
              />
              <img
                className="planeta planeta icono"
                src={Planeta}
                alt="Estrella8"
                title="Estrella8"
              />
              <img
                className="planeta4 planeta4 icono"
                src={Planeta2}
                alt="Estrella9"
                title="Estrella9"
              />
              <img
                className="estrella1 estrella icono"
                src={estrella}
                alt="Estrella10"
                title="Estrella10"
              />
              <img
                className="estrella2 estrella icono"
                src={estrella}
                alt="Estrella11"
                title="Estrella11"
              />
              <img
                className="estrella3 estrella icono"
                src={estrella}
                alt="Estrella12"
                title="Estrella12"
              />
              <img
                className="estrella4 estrella icono"
                src={estrella}
                alt="Estrella13"
                title="Estrella13"
              />
              <img
                className="estrella5 estrella icono"
                src={estrella}
                alt="Estrella14"
                title="Estrella14"
              />
              <img
                className="estrella6 estrella icono"
                src={estrella}
                alt="Estrella15"
                title="Estrella15"
              />
              <img
                className="estrella7 estrella icono"
                src={estrella}
                alt="Estrella16"
                title="Estrella16"
              />
              <img
                className="estrella8 estrella icono"
                src={estrella}
                alt="Estrella17"
                title="Estrella17"
              />
              <img
                className="estrella9 estrella icono"
                src={estrella}
                alt="Estrella18"
                title="Estrella18"
              />
              <img className="planeta1 icono" src={planeta2} alt="planeta" title="planeta1" />
              <img className="planeta2 icono" src={planeta2} alt="planeta" title="planeta2" />
              <img className="planeta3 icono" src={planeta2} alt="planeta" title="planeta3" />
              <img className="ufo icono" src={ufo} alt="ufo" title="ufo"/>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="sliderhome">
      <div className="fondoplanes2"></div>
        <Recursos />
        <span className="FlechaServicio">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72.067"
            height="7.137"
            viewBox="0 0 72.067 7.137"
          >
            <path
              id="Unión_1"
              data-name="Unión 1"
              d="M-4004.024-2031.863a.513.513,0,0,1-.4-.189l-2.462-3.058a.51.51,0,0,1,0-.648l2.548-3.058a.511.511,0,0,1,.719-.066.511.511,0,0,1,.066.719l-1.861,2.231h70.479v1h-70.493l1.8,2.233a.51.51,0,0,1,.068.545.511.511,0,0,1-.46.291Z"
              transform="translate(4007 2039)"
              fill="#000"
            ></path>
          </svg>
        </span>
        <Container>
        <Row>
        <Col>
        <SliderServicios />
        </Col>
          </Row>
        </Container>
        <span className="FlechaServicio">
          Mas servicios
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="226.542"
            height="12.54"
            viewBox="0 0 226.542 12.54"
          >
            <g
              id="Grupo_142"
              data-name="Grupo 142"
              transform="translate(-443.45 -555.74)"
            >
              <path
                id="Unión_2"
                data-name="Unión 2"
                d="M-5308.1-1985.98a.51.51,0,0,1-.066-.719l2.069-2.482H-5377v-1h70.508l-1.6-1.982a.511.511,0,0,1-.069-.545.512.512,0,0,1,.467-.291.51.51,0,0,1,.4.189l2.463,3.058a.512.512,0,0,1,0,.648l-2.549,3.059a.509.509,0,0,1-.393.184A.506.506,0,0,1-5308.1-1985.98Z"
                transform="translate(5850.717 2552.681)"
                fill="#000"
              ></path>
            </g>
          </svg>
        </span>
        <div className="patron">
      <svg  width="100%" height="240px">
      <pattern id="patron-circulo" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
        <circle cx="12" cy="12" r="12" fill="#edeff1"></circle>
      </pattern>

      <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#patron-circulo)"></rect>
    </svg>
      </div>
      </div>
    </div>
  );
}
export default BannermHome;
