import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import videoSoporte from "../../video/soporte.mp4";
import React from "react";

const Bannerm = () => {
  return (
    <section>
      <Container>
        <Row>
        <Col xl={6} md={6} sm={12} className="quitarvideo">
            <video
              className="video"
              title="video mantenimiento"
              src={videoSoporte}
              loop
              muted
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
          <div className="BannerServicio">
            <span className="SubTituloGeneral">Enfócate en hacer crecer tu negocio</span>
            <h1 className="TituloGeneral">Mantenimiento web</h1>
            <p className="ParrafoGeneral">Sabemos que tienes que estar justo donde tu negocio te necesita</p>
            <span className="Referencia"><span className="ReferenciaValor">US$3 billones </span>Usuarios de eCommerce en 2021</span>
            </div>
            <div className="Bloqueleyenda sombra">
            <p className="parrafo">El 54% de las webs del mundo están basadas en WordPress y el 13% de las tiendas online en WooCommerce</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Bannerm;
