import "../../styles/banner.css";
import { BotonCorreo, Botoninsta, BotonWhatapp } from '../boton/Boton-secundario'

function BannerContacto() {
  return (
    <>
      <div className="ContainerServicios" >
        <span className="titulocontacto">Hola,</span>
        <span className="titulocontacto">¿Tienes un proyecto en mente ?</span>
        <p className="titulocontacto3">Si tienes una idea o tienes una web. Escríbenos y cuéntanos lo que quieres hacer.</p>
        <span className="titulocontacto">Redes sociales</span>
        <div>
          <Botoninsta />
          <BotonCorreo />
          <BotonWhatapp />
        </div>
      </div>
      <div className="MasServivios">
        <span>Conoce nuestros servicios principales</span>
      </div>
    </>
  );
}
export default BannerContacto;
