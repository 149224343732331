import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/planes.css";

import Hora from "../../svg/hora.svg";
import {BtnPlanesAvanzado,BtnPlanesEstandar,BtnPlanesPlus, BtnPaginaPlanesEstandar, BtnPaginaPlanesPlus, BtnPaginaPlanesAvanzado } from "../boton/Boton-secundario";

function PlanesPaginaWeb() {
  return (
    <div >
      <div className="fondoplanes"></div>
      <Container>
        <Row className="g-0">
          <Col sm={4}>
            <article>
            <div className="cajaplansuperior"></div>
              <div className="cajaplan">
                <span className="plan ">Estándar</span>
                <span className="oferta"><span className="ofertasimbolo">$</span><span className="lineaprecio">500.000</span>
                </span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>399.000
                </span>
                <span className="ModoPago colorgriss">/ Pago Unico</span>
                <span className="lineabtn"></span>

                <span className="descripcion colorgriss">
                  Ideal para empresas pequeñas que están en expansión
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora"  title="icono hora"/> 4 días
                </span>
                <span className="lineabtn"></span>
                <BtnPaginaPlanesEstandar/>
              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
            <div className="cajaplansuperior2"></div>
              <div className="cajaplan">
                <span className="plan ">Plus</span>
                <span className="oferta"><span className="ofertasimbolo">$</span>
                <span className="lineaprecio">700.000</span>
                </span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>599.000
                </span>
                <span className="ModoPago colorgriss">/ Pago Unico</span>
                <span className="lineabtn"></span>
                <span className="descripcion colorgriss">
                  Para empresas que están comenzando en el mundo digital
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora"  title="icono hora"/> 8 días
                </span>
                <span className="lineabtn"></span>
                <BtnPaginaPlanesPlus/>

              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
            <div className="cajaplansuperior"></div>
              <div className="cajaplancenter">
                <span className="plan ">Avanzado</span>
                <span className="oferta"><span className="ofertasimbolo">$</span><span className="lineaprecio">800.000</span>
                </span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>699.000
                </span>
                <span className="ModoPago colorgriss">/ Pago Unico</span>
                <span className="lineabtn"></span>
                <span className="descripcion">
                  Para negocios que ya definieron su nicho en el mercado
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="icono hora" /> 15 días
                </span>
                <span className="lineabtn"></span>
                <BtnPaginaPlanesAvanzado/>
              </div>
            </article>
          </Col>
        </Row>
 
      </Container>  
      <div className="patron">
      <svg  width="100%" height="240px">
      <pattern id="patron-circulo" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
        <circle cx="12" cy="12" r="12" fill="#edeff1"></circle>
      </pattern>

      <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#patron-circulo)"></rect>
    </svg>
      </div>
    </div>
  );
}

export default PlanesPaginaWeb;