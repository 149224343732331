import React from "react";
import { SliderServidor } from "./slider";

function ServidorBloque() {
  return (
    <section className="ColorGriss">
      <div className="BloqueTitulo Bloqueespacio">
        <h2 className="TituloGeneral">
          GESTIONAMOS TU <span className="TituloSecundario">ALOJAMIENTO WEB</span>
        </h2>
        <p className="ParrafoGeneral">
          Hacemos una gestión integral de la presencia online de tu negocio,
          tanto de marketing como de infraestructura. Contamos con un equipo de
          informáticos especialistas en servidores para brindarte todo el apoyo
          técnico necesario para que tu web cargue rápido y sin errores.
        </p>
      </div>
      <SliderServidor />
    </section>
  );
}
export default ServidorBloque;
