import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import videoServidor from "../../video/servidor.mp4";
import React from "react";

function Banners() {
  return (
    <section>
      <Container>
        <Row>
        <Col xl={6} md={6} sm={12} className="Bloqueinterno quitarvideo">
            <video
              className="video"
              title="video servidores"
              src={videoServidor}
              loop
              muted
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <div className="BannerServicio">
            <span className="SubTituloGeneral">Rápido, seguro.</span>
            <h1 className="TituloGeneral">Servidor web</h1>
            <p className="ParrafoGeneral">Hoy en día contar con una página segura es muy importante.</p>
            <span className="Referencia"><span className="ReferenciaValor">9 millones </span>de sitios web activos que utilizan AWS</span>
            </div>
            <div className="Bloqueleyenda sombra">
            <p className="parrafo">Aprovecha la seguridad y la fiabilidad de la plataforma en la nube líder en el mundo.</p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
}
export default Banners;
