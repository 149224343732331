import SubTonine from "../../componentes/submenus/SubTonline";
import ListaServicios from "../../componentes/Bloques/ListaServicios";
import DetalleTiendaOnline from "../../componentes/Planes/DetalleTiendaOnline";
import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import { NoteTiendaonline } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function ETonline() {
  return (
    <>
      <Helmet>
        <title>Especificaciones tienda online antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Conoce nuestros diferentes planes de tienda online. vende tus producto en una eccomerce, que te ayude a mejorar tus ventas"
        />
      </Helmet>
      <SubTonine />
      <DetalleTiendaOnline />
      <ListaServicios />
      <Clientes />
      <NoteTiendaonline />
      <SubFooter />
    </>
  );
}
export default ETonline;
