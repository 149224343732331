import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ListaServicios2() {
  return (
    <section className="ColorGriss">
      <Container>
        <Row>
          <Col sm={12} xl={12}>
            <div className="BloqueCenter BloqueTitulo">
              <span className="SubTituloGeneral">
                Precios sencillos, económico y escalables.
              </span>
              <h2 className="TituloGeneral">
                Creación de cuentas e integraciones
              </h2>
              <p className="ParrafoGeneral">
                Saca el máximo provecho de tu pagina web con las diferentes
                herramientas
              </p>
            </div>
          </Col>
          <Col sm={12} xl={6}>
            <ListGroup as="ul">
              <ListGroup.Item as="li" active>
                Servicios
              </ListGroup.Item>
              <ListGroup.Item as="li">Creación e integración Google ADS</ListGroup.Item>
              <ListGroup.Item as="li">Integración Google Analytics</ListGroup.Item>
              <ListGroup.Item as="li">Integración Google Search Console</ListGroup.Item>
              <ListGroup.Item as="li">Formularios de Contacto</ListGroup.Item>
              <ListGroup.Item as="li">Google reCAPTCHA</ListGroup.Item>
              <ListGroup.Item as="li">Cerfificado de seguridad SSL</ListGroup.Item>
              <ListGroup.Item as="li">Rediseño de páginas</ListGroup.Item>
              <ListGroup.Item as="li">Implementación de pasarela de pago</ListGroup.Item>
              <ListGroup.Item as="li">Optimización de performance</ListGroup.Item>
              <ListGroup.Item as="li">Optimización de imagenes</ListGroup.Item>
              <ListGroup.Item as="li">Servicio de carga de productos web</ListGroup.Item>
              <ListGroup.Item as="li">Servicio de mantencíon web</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} xl={6}>

          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ListaServicios2;
