
function SubOferta() {
  return (
    <div className="SubOferta">
         ¿no sabes si tu web está optimizado? !Obtén asesoría gratis!
    </div>
  );
}

export default SubOferta;
