import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import videotiendaonline from "../../video/tienda-online.mp4";
import React from "react";
import { BtnSecundarioEconomicas } from "../boton/Boton-secundario";


function Bannerto() {
  return (
    <section>
      <Container>
        <Row>
        <Col xl={6} md={6} sm={12} className="quitarvideo">
            <video
              className="video"
              title="video tienda onlie"
              src={videotiendaonline}
              loop
              muted
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
           <div className="BannerServicio">
            <span className="SubTituloGeneral">Transforma tu negocio</span>
            <h1 className="TituloGeneral">Necesita una tienda online</h1>
            <p className="ParrafoGeneral">Aumenta la visibilidad de tu negocio y vende al público las 24 horas del día.</p>
            <span className="Referencia"><span className="ReferenciaValor">12 millones </span>Usuarios de eCommerce en 2021</span>
            </div>
            <div className="Bloqueleyenda sombra">
            <p className="parrafo">El 54% de las webs del mundo están basadas en WordPress y el 13% de las tiendas online en WooCommerce</p>
            </div>
            <p className="parrafo">Estás recién comenzando! Empieza con una tienda online barata.</p>
            <BtnSecundarioEconomicas />
          </Col>

        </Row>
      </Container>
    </section>
  );
}
export default Bannerto;
