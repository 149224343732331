import "../index.css";
import React from 'react';
import Helmet from "react-helmet";

function PcMac() {
  return (
    <>
          <Helmet>
          <title>Formateo y reparación de mac windows pc antofagasta - Penseo.cl</title>  
          <meta
          name="description"
          content="Diseño paǵina web profesional para ayudar a las empresas a aumentar el tráfico, mejorar la presencia en línea y generar leads. Nuestros servicios incluyen diseño, optimización para motores de búsqueda."
        />

    </Helmet>

    </>
  );
}
export default PcMac;
