import BannertoHome from "../Banner/Banner-to-Home";
import BannerpwHome from "../Banner/Banner-pw-Home";
import Bannermm from "../Banner/Banner-servidor-home";
import BannersHome from "../Banner/Banner-Mate-Home";
import BannerContacto from "../Banner/Banner-Contacto";
import "swiper/css";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Bloquecorreo,
  Bloqueimagen,
  Bloqueportafolio,
  Bloquerediseño,
  Bloquevelocidad,
  Bloqueservicio,
  Bloquesll,
  Bloqueproducto,
  Bloquepago,
  Bloquedatos,
  Bloquewoocommerce,
  Bloquewordpress,
  Bloquetag,
  Bloquesearch,
  Bloquehotjar,
  Bloqueanalitycs,
  Bloqueaws,
  Bloquegodaddy,
} from "./BloqueServicio";
import BannerEconomico from "../Banner/Banner-economico";

export function SliderPaginaWeb() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiperSpace"
      >
        <SwiperSlide>
          <Bloqueportafolio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueservicio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquecorreo />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesll />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueimagen />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquerediseño />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquevelocidad />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export function SliderTiendaOnline() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiperSpace"
      >
        <SwiperSlide>
          <Bloqueportafolio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueservicio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquecorreo />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesll />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueimagen />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquerediseño />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquevelocidad />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueproducto />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquepago />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export function SliderMantenimiento() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiperSpace"
      >
        <SwiperSlide>
          <Bloqueportafolio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueservicio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquecorreo />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesll />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueimagen />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquerediseño />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquevelocidad />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueproducto />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquepago />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export function SliderServidor() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiperSpace"
      >
        <SwiperSlide>
          <Bloqueportafolio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueservicio />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquecorreo />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesll />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueimagen />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquedatos />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquerediseño />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquevelocidad />
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueproducto />
        </SwiperSlide>
        <SwiperSlide>
          <Bloquepago />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export function SliderServicios() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
  
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 1,
          },
          768: {
            slidesPerView:2,
            spaceBetween: 1,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <BannerContacto />
        </SwiperSlide>
        <SwiperSlide>
          <BannerEconomico />
        </SwiperSlide>
        <SwiperSlide>
          <BannersHome />
        </SwiperSlide>
        <SwiperSlide>
          <Bannermm />
        </SwiperSlide>
        <SwiperSlide>
          <BannerpwHome />
        </SwiperSlide>
        <SwiperSlide>
          <BannertoHome />
        </SwiperSlide>

      </Swiper>
    </>
  );
}
export function SliderHerramientaweb() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Bloquewordpress/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquetag/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesearch/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquehotjar/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueanalitycs/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export function SliderHerramientatienda() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Bloquewoocommerce/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquewordpress/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquetag/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquesearch/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquehotjar/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloqueanalitycs/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export function SliderHerramientaservidores() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >

        <SwiperSlide>
          <Bloqueaws/>
        </SwiperSlide>
        <SwiperSlide>
          <Bloquegodaddy/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}