import "../index.css";
import SubCasosExito from "../componentes/submenus/SubCasosExito";
import gsap from "gsap";
import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SubFooter from "../componentes/submenus/SubFooter";
import SubOferta from "../componentes/submenus/SubOferta";
import ecoasis from "../imagenes/clientes/ecoasis.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Helmet from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
gsap.registerPlugin(ScrollTrigger);

function Cexito() {
  useEffect(() => {
    gsap.from(".caja", {
      scrollTrigger: {
        scrub: 1,
      },

      scale:3,
    });

    ScrollTrigger.refresh();
  });
  return (
    <>
      <Helmet>
        <title>Casos de exito - página web - Penseo.cl</title>
        <meta
          name="description"
          content="conoce nuestra cartera de trabajos, en todas las areas del mercado como abogados, dentistas, tienda de productos y servicio"
        />
      </Helmet>
      <SubCasosExito />
      <Container>
        <Row>
        <Col sm={12} xl={12}>
        <h1>Casos de éxito</h1>
        <h2>página web</h2>
        <p>trabajos web</p>

          </Col>

        </Row>
      </Container>


      <Swiper
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1920: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiperSpace"
      >
        <SwiperSlide className="caja">
        <LazyLoadImage className="imgcliente" src={ecoasis} alt="ecoasis"  title="ecoasis"/>
        </SwiperSlide>
        <SwiperSlide  className="caja">
        <LazyLoadImage className="imgcliente" src={ecoasis} alt="ecoasis"  title="ecoasis"/>
        </SwiperSlide>
        <SwiperSlide  className="caja">
        <LazyLoadImage className="imgcliente" src={ecoasis} alt="ecoasis"  title="ecoasis"/>
        </SwiperSlide>

      </Swiper>



      <SubOferta />
      <SubFooter />
    </>
  );
}

export default Cexito;
