import "tippy.js/dist/tippy.css"; // optional for styling
import "tippy.js/themes/light.css";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import React from "react";
import { NavLink } from "react-router-dom";

import whatsapp from "../../svg/whatsapp.svg";
import Inicio from "../../svg/inicio.svg";
import Flecha from "../../svg/flecha.svg";
import FlechaInfo from "../../svg/flechainfo.svg";
import FlechaArriba from "../../svg/flachaArriba.svg";

export function BtnContacto() {
  return (
    <>
      <Tippy placement="top" content="Formulario de contacto">
        <a
          aria-label="Formulario de contacto"
          className="BotonGeneral sombra BloqueCenter BtnDefault botonefecto"
          href="https://penseo.odoo.com/contacto"
          title="contacto"
        >
          Quiero saber más
        </a>
      </Tippy>
    </>
  );
}
export function BtnSecundarioTiendaOnline() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestros planes de tienda online">
        <NavLink
          alt="Conoce nuestros planes de tienda online"
          aria-label="Más información sobre tienda online"
          to="/tienda-online"
          className="BotonSecundario BloqueCenter BtnDefault"
          title="tienda online"
        >
          Más info aquí
          <img className="webflecha" src={Flecha} alt="flecha servivios" title="flecha servicios web" />
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnSecundarioPaginaWeb() {
  return (
    <>
      <Tippy
        placement="bottom"
        content="Conoce nuestros planes de página web"
      >
        <NavLink
          alt="Conoce nuestros planes de página web"
          aria-label="Más información sobre página web"
          to="/pagina-web"
          className="BotonSecundario BloqueCenter BtnDefault"
          title="página web"
        >
          Ver más <img  src={Flecha} className="webflecha" alt="flecha servivios" title="flecha servicios web" />
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnSecundarioMantenimiento() {
  return (
    <>
      <Tippy
        placement="bottom"
        content="Conoce nuestros planes de mantenimiento web"
      >
        <NavLink
          alt="Conoce nuestros planes de mantenimiento web"
          aria-label="Más información sobre mantenimiento web"
          to="/mantenimiento"
          className="BotonSecundario BloqueCenter BtnDefault"
          title="mantenimiento web"
        >
          Conoce más <img src={Flecha} className="webflecha" alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnSecundarioServidor() {
  return (
    <>
      <Tippy
        placement="bottom"
        content="Conoce nuestros planes de servidor web"
      >
        <NavLink
          alt="Conoce nuestros planes de servidor web"
          aria-label="Más información sobre servidor web"
          to="/otro-servicio"
          className="BotonSecundario BloqueCenter BtnDefault"
          title="servidor web"
        >
          Más infromación <img src={Flecha} className="webflecha" alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnSecundarioClientes() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro portafolio">
        <NavLink
          aria-label="Más información sobre nuestro portafolio"
          className="Botoninfo BtnDefault"
          to="/casos-exito"
          alt="Nuesto portafolio"
          title="portafolio"
        >
          Más información <img src={FlechaInfo} className="webflecha" alt="flecha servivios"  title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnSecundarioEconomicas() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestras web economicas">
        <NavLink
          aria-label="Más información sobre nuestras web economicas"
          className="BotonSecundario BloqueCenter BtnDefault"
          to="/web-economicas"
          alt="Nuesto portafolio"
          title="web economicas"
        >
          Lo quiero <img src={Flecha} className="webflecha" alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnPortafolio() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro portafolio">
        <NavLink
          aria-label="Más información sobre nuestro portafolio"
          className="txtcolor2 BtnDefault"
          to="/caso-exito"
          alt="Nuesto portafolio"
          title="portafolio"
        >
          portafolio
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnEspeMantenimiento() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce mas especificaciones">
        <NavLink
          aria-label="Más información sobre nuestro portafolio"
          className="Boton Botoninfo BloqueCenter BtnDefault"
          to="/especificaciones-manteniminto"
          title="mantenimiento web"
        >
          Más información <img className="webflecha" src={FlechaInfo} alt="flecha servivios" title="flecha servicios web" />
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnEspePaginaWeb() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce mas especificaciones">
        <NavLink
          aria-label="Más información sobre servicio web"
          className="Boton Botoninfo BloqueCenter BtnDefault"
          to="/especificaciones-pagina-web"
          title="especificaciones web"
        >
          Más información <img className="webflecha" src={FlechaInfo} alt="flecha servivios" title="flecha servicios web" />
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnEspeServidor() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce mas especificaciones servidores">
        <NavLink
          aria-label="Más información sobre nuestro servidores"
          className="Boton Botoninfo BloqueCenter BtnDefault"
          to="/especificaciones-otros-servivio"
          title="especificaciones servidores"
        >
          Más información <img className="webflecha" src={FlechaInfo} alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnEspeTiendaOnline() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce más especificaciones tienda online">
        <NavLink
          aria-label="Más información sobre nuestras tienda online"
          className="Boton Botoninfo BloqueCenter BtnDefault"
          to="/especificaciones-tienda-online"
          title="especificaciones tienda online"
        >
          Más información <img className="webflecha" src={FlechaInfo} alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnEspeOtros() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce mas especificaciones otros servicios">
        <NavLink
          aria-label="Más información sobre nuestros servicios"
          className="Boton Botoninfo BloqueCenter BtnDefault"
          to="/especificaciones-pagina-web"
          title="especificaciones otros servicios"
        >
          Más información <img className="webflecha" src={FlechaInfo} alt="flecha servivios" title="flecha servicios web"/>
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnVolverInicio() {
  return (
    <>
      <Tippy content="Volver al inicio" placement="bottom">
        <NavLink className="mobilsubmenu" to="/" aria-label="Volver al inicio" title="volvel a inicio">
          <img  src={Inicio} alt="Svg inicio" title="volver al inicio" />
        </NavLink>
      </Tippy>
    </>
  );
}
export function BtnVolverArriba() {
  return (
    <>
      <Tippy placement="top" content="Volver arriba">
        <a aria-label="Volver arriba" href="#volverinicio" title="boton volvel arriba">
          <img className="webflecha" src={FlechaArriba} alt="Svg flecha arriba" title="volver arriba" />
        </a>
      </Tippy>
    </>
  );
}
export function Botoncotizar() {
  return (
    <>
      <Tippy placement="bottom" content="Cotiza tu proyecto">
        <a
          aria-label="Cotiza tu proyecto"
          href="https://penseo.odoo.com/contacto"
          className="BotonSecundario BloqueCenter BtnDefault"
        >
          Cotizar ahora <img className="webflecha" src={Flecha} alt="flecha contacto" title="flecha servicios web"/>
        </a>
      </Tippy>
    </>
  );
}
export function Botoninsta() {
  return (
    <>
      <Tippy placement="bottom" content="Instagrams">
        <a
          className="btnContactoHome"
          aria-label="Instagrams"
          href="https://www.instagram.com/penseo.antofagasta/?hl=es"
          title="instagrams"
        >
          <svg
            width="48px"
            height="48px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#fff"
          >
            <path
              d="M12 16a4 4 0 100-8 4 4 0 000 8z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M3 16V8a5 5 0 015-5h8a5 5 0 015 5v8a5 5 0 01-5 5H8a5 5 0 01-5-5z"
              stroke="#fff"
              stroke-width="1.5"
            ></path>
            <path
              d="M17.5 6.51l.01-.011"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </a>
      </Tippy>
    </>
  );
}
export function BotonCorreo() {
  return (
    <>
      <Tippy placement="bottom" content="Correo">
        <a
          className="btnContactoHome"
          aria-label="Correo"
          href="mailto:contacto@penseo.cl"
          title="Correo penseo"
        >
          <svg
            width="48px"
            height="48px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#fff"
          >
            <path
              d="M7 9l5 3.5L17 9"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2 17V7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2z"
              stroke="#fff"
              stroke-width="1.5"
            ></path>
          </svg>
        </a>
      </Tippy>
    </>
  );
}
export function BotonWhatapp() {
  return (
    <>
      <Tippy placement="bottom" content="WhatsApp">
        <a
          className="btnContactoHome"
          aria-label="WhatsApp"
          href="https://wa.me/56988844550"
          title="whatapp penseo"
        >
          <img className="webflecha" src={whatsapp} alt="Svg flecha" title="flecha servicios web"/>
        </a>
      </Tippy>
    </>
  );
}
export function Botoninstafooter() {
  return (
    <>
      <Tippy placement="bottom" content="Instagrams">
        <a
          className="btnContactoHome"
          aria-label="Instagrams"
          href="https://www.instagram.com/penseo.antofagasta/?hl=es"
          title="instagrams penseo"        >
          <svg
            width="48px"
            height="48px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#fff"
          >
            <path
              d="M12 16a4 4 0 100-8 4 4 0 000 8z"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M3 16V8a5 5 0 015-5h8a5 5 0 015 5v8a5 5 0 01-5 5H8a5 5 0 01-5-5z"
              stroke="#000"
              stroke-width="1.5"
            ></path>
            <path
              d="M17.5 6.51l.01-.011"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </a>
      </Tippy>
    </>
  );
}
export function BotonCorreofooter() {
  return (
    <>
      <Tippy placement="bottom" content="Correo">
        <a
          className="btnContactoHome"
          aria-label="Correo"
          href="mailto:contacto@penseo.cl"
          title="Contacto"
        >
          <svg
            width="48px"
            height="48px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000"
          >
            <path
              d="M7 9l5 3.5L17 9"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2 17V7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2z"
              stroke="#000"
              stroke-width="1.5"
            ></path>
          </svg>
        </a>
      </Tippy>
    </>
  );
}
export function BotonTicket() {
  return (
    <>
      <Tippy placement="bottom" content="Correo">
        <a
          className="btnContactoHome"
          aria-label="Correo"
          href="https://penseo.odoo.com/ticket-de-soporte"
          alt="tiket soporte"
          title="tiket soporte"
        >
          Empezar ahora
        </a>
      </Tippy>
    </>
  );
}
export function BtnProtafolioHome() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro portafolio">
        <NavLink
          aria-label="Más información sobre nuestro portafolio"
          className="txtcolor2 BtnDefault"
          to="/caso-exito"
          alt="Nuesto portafolio"
          title="Portafolio"
        >
          Portafolio
        </NavLink>
      </Tippy>
    </>
  );
}


export function BtnTiendaPlanesAvanzado() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan avanzado">
        <a
          aria-label="Más información sobre nuestro plan avanzado"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/to003-tienda-online-avanzado-8#attr=57,23,24,47"
          alt="Nuesto plan avanzado"
          title="plan avanzado"
        >
         eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnTiendaPlanesEstandar() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan estandar">
        <a
          aria-label="Más información sobre nuestro plan estandar"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/to001-tienda-online-estandar-6#attr=55,17,18,45"
          alt="Nuesto plan estandar"
          title="plan estandar"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnTiendaPlanesPlus() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan plus">
        <a
          aria-label="Más información sobre nuestro plan plus"
          className="btnPlanes1 BtnDefault"
          href="https://tienda.penseo.cl/shop/to002-tienda-online-plus-7#attr=56,20,21,46"
          alt="Nuesto plan plus"
          title="plan web plus"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}




export function BtnPaginaPlanesAvanzado() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan avanzado">
        <a
          aria-label="Más información sobre nuestro plan avanzado"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/web003-pagina-web-avanzado-12#attr=51,2,3"
          alt="Nuesto plan avanzado"
          title="plan avanzado"
        >
         eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnPaginaPlanesEstandar() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan estandar">
        <a
          aria-label="Más información sobre nuestro plan estandar"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/web001-pagina-web-estandar-10#attr=49,37,38"
          alt="Nuesto plan estandar"
          title="plan estandar"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnPaginaPlanesPlus() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan plus">
        <a
          aria-label="Más información sobre nuestro plan plus"
          className="btnPlanes1 BtnDefault"
          href="https://tienda.penseo.cl/shop/web002-pagina-web-plus-11#attr=50,40,41"
          alt="Nuesto plan plus"
          title="plan web plus"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}


export function BtnServidoresPlanesAvanzado() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan avanzado">
        <a
          aria-label="Más información sobre nuestro plan avanzado"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/servidor003-servidor-avanzado-5#attr=12,13,14,15"
          alt="Nuesto plan avanzado"
          title="plan avanzado"
        >
         eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnServidoresPlanesEstandar() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan estandar">
        <a
          aria-label="Más información sobre nuestro plan estandar"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/servidor001-servidor-estandar-2#attr=4,5,6,7"
          alt="Nuesto plan estandar"
          title="plan estandar"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnServidoresPlanesPlus() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan plus">
        <a
          aria-label="Más información sobre nuestro plan plus"
          className="btnPlanes1 BtnDefault"
          href="https://tienda.penseo.cl/shop/servidor002-servidor-plus-4#attr=8,9,10,11"
          alt="Nuesto plan plus"
          title="plan web plus"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}



export function BtnLandingPlanesAvanzado() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan avanzado">
        <a
          aria-label="Más información sobre nuestro plan avanzado"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/web004-landing-page-9#attr=25,26,27"
          alt="Nuesto plan avanzado"
          title="plan avanzado"
        >
         eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnPWEcoPlanesEstandar() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan estandar">
        <a
          aria-label="Más información sobre nuestro plan estandar"
          className="btnPlanes2 BtnDefault"
          href="https://tienda.penseo.cl/shop/web000-pagina-web-economica-64#attr=52,43,44"
          alt="Nuesto plan estandar"
          title="plan estandar"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}
export function BtnTOEcoPlanesPlus() {
  return (
    <>
      <Tippy placement="bottom" content="Conoce nuestro plan plus">
        <a
          aria-label="Más información sobre nuestro plan plus"
          className="btnPlanes1 BtnDefault"
          href="https://tienda.penseo.cl/shop/to000-tienda-online-economica-63#attr=53,33,34,54"
          alt="Nuesto plan plus"
          title="plan web plus"
        >
          eligió el plan
        </a>
      </Tippy>
    </>
  );
}

