import "../../styles/planes.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function DetalleMantenimiento() {
  return (
    <section>
      <Container className="bloquedetalle">
        <Row>
          <Col>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th className="columna">Características</th>
                    <th>
                    Por hora
                      <sup>
                        <a className="footnote" href="#footnote1" title="Asesoria por hora">
                          1
                        </a>
                      </sup>
                    </th>
                    <th>
                    Tiempo parcial
                      <sup>
                        <a className="footnote" href="#footnote2" title="asesoria parcial">
                          2
                        </a>
                      </sup>
                    </th>
                    <th>
                    Tiempo completo
                      <sup>
                        <a className="footnote" href="#footnote3"  title="asesoria timpo completo">
                          3
                        </a>
                      </sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="linea">
                    <td className="nombrePlantabla">
                      Asesoria
                    </td>
                    <td className="boxdatos fondotabla">Por horas</td>
                    <td className="boxdatos">4 Horas diarias</td>
                    <td className="boxdatos fondotabla">8 Horas diarias</td>
                  </tr>
                  <tr className="linea2">
                    <td className="nombrePlantabla">Precio</td>
                    <td className="boxdatos fondotabla">$6.900</td>
                    <td className="boxdatos">$27.900</td>
                    <td className="boxdatos fondotabla">$55.900</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DetalleMantenimiento;
