import SubFooter from "../../componentes/submenus/SubFooter";
import PlanesWebEconomica from "../../componentes/Planes/PlanesWebEconomicas";
import SubWebEconomicas from "../../componentes/submenus/SubWebEconomicas";
import Helmet from "react-helmet";
function PrecioWebEconomicas() {
  return (
    <>
      <Helmet>
        <title>Precios web economicas antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Los precios de los servicios de web economicas dependen de varios factores, como el tamaño y la complejidad del sitio web, los recursos requeridos para su construcción, las características y funcionalidades deseadas y el tiempo de entrega."
        />
      </Helmet>
      <SubWebEconomicas />
      <PlanesWebEconomica />
      <SubFooter />
    </>
  );
}
export default PrecioWebEconomicas;
