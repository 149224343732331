import servidorweb from "../../imagenes/servidor-web.webp";
import "../../styles/banner.css";
import { BtnSecundarioServidor } from "../boton/Boton-secundario";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Bannermm() {
  return (
    <div className="cajahome">
      <picture>
        <LazyLoadImage
          className="imgSliderHome"
          src={servidorweb}
          alt="subiendo catalogo a su servidor"
          title="Servidor web"
          effect='blur'
        />
      </picture>
      <div className="cajatexto">
        <div className="bloquesubtitulo">
          <h2 className="TituloSlider">Servidor Web</h2>
          <sup>
            <a
              aria-label="Nota 3 ¿qué es un servidor web?"
              className="footnote"
              href="#footnote3"
              title="ver mas sobre servidor web"
            >
              3
            </a>
          </sup>
        </div>
        <div className="DescripSlider">Rápido, seguro y escalable.</div>
        <span className="PrecioSlider">
          <span className="pesoshome">$</span>54.990
        </span>
        <div className="boxbotonsecundario">
          <BtnSecundarioServidor />
        </div>
      </div>
    </div>
  );
}
export default Bannermm;
