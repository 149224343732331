import React from "react";
import { SliderMantenimiento } from "./slider";

function BloqueMante() {
  return (
    <section className="ColorGriss">
      <div className="Bloqueespacio BloqueTitulo">
        <h2 className="TituloGeneral BloqueTitulo">
          SERVICIO<span className="TituloSecundario"> MANTENIMIENTO </span>WEB
        </h2>
        <p className="ParrafoGeneral">
          En penseo prestamos servicios de mantenimiento web. ¿Qué te garantiza
          este servicio? Pues que tu web funcione correctamente, sin que ningún
          problema o imprevisto la haga caerse.
        </p>
        <p className="ParrafoGeneral">
          Con este servicio no tendrás que preocuparte del mantenimiento de tu
          web. Ya que se realizaran copias de seguridad, actualizaciones de
          plugin y el rendimiento de tu sitio web. Para que de lo único que
          tengas que preocuparte es de tu negocio.
        </p>
      </div>

      <SliderMantenimiento />
    </section>
  );
}
export default BloqueMante;
