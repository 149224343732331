import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { BtnVolverInicio } from "../boton/Boton-secundario";

function SubPweb() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
          <BtnVolverInicio />
          <Navbar.Brand className="mobilsubmenu BtnMovil">Página web</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <NavLink
              className="BtnSubMenu BtnDefault"
              to="/pagina-web"
              aria-label="pagina web"
              title="página web"
            >
              Descripción
            </NavLink>
            <NavLink
              className="BtnSubMenu BtnDefault"
              to="/precio-paginaWeb"
              aria-label="precio página web"
              title="precio página web"
            >
              Precios
            </NavLink>
            <NavLink
              className="BtnSubMenu BtnDefault"
              to="/especificaciones-pagina-web"
              aria-label="Especificaciones pagina web"
              title="Especificaciones página web"
            >
              Especificaciones
            </NavLink>
            <a
              title="cotizar página web"
              aria-label="Cotizar servicios"
              className="BtnSubMenu2 BtnDefault BtnMovil"
              href="https://penseo.odoo.com/cotizar-pagina-web"
            >
              Cotizar
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default SubPweb;
