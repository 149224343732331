import React from "react";
import { SliderPaginaWeb } from "./slider";

function Vendelinea2() {
  return (
    <section className="ColorGriss">
      <div className="BloqueTitulo Bloqueespacio">
        <h2 className="TituloGeneral">
          DISEÑO WEB <span className="TituloSecundario">WORDPRESS</span> CORPORATIVO
        </h2>
        <p className="ParrafoGeneral">
          Una página web en WordPress es fundamental para que tu sitio tenga
          éxito. Debe reflejar la personalidad de tu negocio y sobre todo, debe
          estar diseñado para tus clientes. De hecho, no solo basta con estar en
          Internet, sino que hay que aplicar las acciones y estrategias
          adecuadas para poder sacarle el máximo partido.
        </p>
      </div>
      <SliderPaginaWeb />
    </section>
  );
}
export default Vendelinea2;
