import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/planes.css";

import Hora from "../../svg/hora.svg";

function PlanesMantenimineto() {
  return (
    <div >
      <div className="fondoplanes"></div>
      <Container>
        <Row className="g-0">
          <Col sm={4}>
            <article>
              <div className="cajaplan">
                <span className="plan ">Por hora</span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>6.900
                </span>
                <span className="ModoPago colorgriss">/ Por hora</span>
                <span className="lineabtn"></span>

                <span className="descripcion colorgriss">
                Para empresas que están comenzando en el mundo digital
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="icono hora"/> 40 Horas
                </span>
                <span className="lineabtn"></span>

              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
              <div className="cajaplan">
                <span className="plan ">Tiempo parcial</span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>27.900
                </span>
                <span className="ModoPago colorgriss">/ 4 Horas diarias</span>
                <span className="lineabtn"></span>
                <span className="descripcion colorgriss">
                Ideal para empresas pequeñas que están en expansión
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="icono hora"/> 1 Mes (84 Horas)
                </span>
                <span className="lineabtn"></span>


              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
              <div className="cajaplancenter">
                <span className="plan ">Tiempo completo</span>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>55.900
                </span>
                <span className="ModoPago colorgriss">/ 8 Horas diarias</span>
                <span className="lineabtn"></span>
                <span className="descripcion">
                  Para negocios que ya definieron su nicho en el mercado
                </span>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="icono hora"/>  1 Mes (168 Horas)
                </span>
                <span className="lineabtn"></span>
   
              </div>
            </article>
          </Col>
        </Row>
 
      </Container>  
      <div className="patron">
      <svg  width="100%" height="240px">
      <pattern id="patron-circulo" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
        <circle cx="12" cy="12" r="12" fill="#edeff1"></circle>
      </pattern>

      <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#patron-circulo)"></rect>
    </svg>
      </div>
    </div>
  );
}

export default PlanesMantenimineto;