import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import PlanesServidor from "../../componentes/Planes/PlanesServidor";
import SubOservicios from "../../componentes/submenus/SubOservicios";
import Helmet from "react-helmet";
function PaginaServidores() {
  return (
    <>
      <Helmet>
        <title>Precio servidores web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Los precios de los servidores web dependen de varios factores, como el tamaño y la complejidad del sitio web, los recursos requeridos para su construcción, las características y funcionalidades deseadas y el tiempo de entrega."
        />
      </Helmet>
      <SubOservicios />
      <PlanesServidor />
      <Clientes />
      <SubFooter />
    </>
  );
}
export default PaginaServidores;
