import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { BtnVolverInicio } from "../boton/Boton-secundario";

function SubMantenimineto() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
          <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu BtnMovil">
            Mantenimiento Web
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/mantenimiento"
                aria-label="Descripción mantenimiento web"
                title="mantenimiento web"
              >
                Descripción
              </NavLink>
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/precio-mantenimiento"
                aria-label="precio mantenimiento web"
                title="precio mantenimiento web"
              >
                Precio
              </NavLink>
              <NavLink
                className="BtnSubMenu BtnDefault"
                to="/especificaciones-manteniminto"
                aria-label="Especificaciones técnicas"
                title="Especificaciones mantenimiento web"
              >
                Especificaciones
              </NavLink>
               <a
            title="cotizar web"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault BtnMovil"
            href="https://penseo.odoo.com/contacto">
            Cotizar
              </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default SubMantenimineto;
