import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
/*
window.addEventListener
("blur",()=> {
  document.title="Vuelve :(";
})
window.addEventListener
("focus",()=> {
  document.title="Penseo.cl";
})
*/
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,

  document.getElementById('root')
)

reportWebVitals();