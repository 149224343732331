import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function Footernote() {
  return (
    <div className="BloqueNote">
      <Container>
        <Row>
          <Col>
          <h2 className="notetitulo">Agencia seo, sitio web, comercio electrónico y servidores en Antofagasta</h2>
            <ol>
              <li className="listaNote" id="footnote1">
              Diseñamos y Creamos tu Página Web barata y Profesional.
              Promociona tu negocio, empieza a vender online, muestra tu trabajo a todo chile.
              </li>
              <li className="listaNote" id="footnote2">
                El mantenimiento web garantiza que la imagen de tu empresa en
                Internet se conserve atractiva, funcional y destacada con el
                tiempo.
              </li>
              <li className="listaNote" id="footnote3">
                Un servidor es un dispositivo virtual que le brinda espacio y
                estructura a los sitios web para que almacenen sus datos y
                manejen sus páginas.
              </li>
              <li className="listaNote" id="footnote4">
                Una página web es el conjunto de información que un sitio web
                muestra en una pantalla, incluyendo a la totalidad de sus
                elementos (textos, imágenes, contenidos multimedia, entre
                otros).
              </li>
              <li className="listaNote" id="footnote5">
                Una tienda en línea se refiere a un tipo de comercio que usa
                como medio principal para realizar sus transacciones un sitio
                web. Constituye la herramienta principal del comercio
                electrónico.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export function NoteTiendaonline() {
  return (
    <section className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <ol>
              <li className="listaNote" id="footnote1">
                Las páginas internas son una especie de organización jerárquica
                dentro del sitio web, se utilizan para ordenar la información.
              </li>
              <li className="listaNote" id="footnote2">
                WordPress se ha convertido en el CMS líder a nivel mundial. La
                versatilidad que brinda a nivel de diseño web en WordPress, así
                como la facilidad de implementación y gestión de las webs
                realizadas con este CMS son la principal causa de su éxito.
              </li>
              <li className="listaNote" id="footnote3">
                El cliente debe entregar la información e imagen de cada
                producto
              </li>
              <li className="listaNote" id="footnote4">
                Nuestras páginas web suelen tener un plazo medio de 15 días
                laborales, aunque estos van a depender en gran medida de las
                características de tu proyecto.
              </li>
              <li className="listaNote" id="footnote5">
                El SEO o posicionamiento orgánico web, es una estrategia de
                marketing digital que tiene como principal objetivo que el
                tienda online se muestre en las primeras posiciones de los
                buscadores.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export function NoteServidor() {
  return (
    <section className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <ol>
              <li className="listaNote" id="footnote1">
                Plan estándar - 512 MB de RAM - Procesador de un núcleo - 20 GB
                de capacidad SSD - 1 TB de transferencia a 54.750 Pesos con IVA
              </li>
              <li className="listaNote" id="footnote2">
                Plan plus - 1 GB de RAM - Procesador de un núcleo - 40 GB de
                capacidad SSD - 2 TB de transferencia a 79.935 Pesos con IVA
              </li>
              <li className="listaNote" id="footnote3">
                Plan plus - 2 GB de RAM - Procesador de un núcleos - 60 GB de
                capacidad SSD - 3 TB de transferencia a 152.935 Pesos con IVA
              </li>
              <li className="listaNote" id="footnote4">
                Página informativa
                <br />
                Son sitios que permiten que cualquier persona pueda escribir
                artículos y publicarlos en la web. Si quisieras, podrías crear
                tu propio blog para que compartas tus experiencias o
                conocimientos sobre un tema de interés personal o profesional.
              </li>
              <li className="listaNote" id="footnote5">
                Sitios corporativos
                <br />
                Los sitios corporativos o institucionales son los sitios
                oficiales de empresas o instituciones, cuyo propósito es ofrecer
                información general sobre su actividad y otras características
                importantes como datos de contacto, oportunidades laborales,
                historia, eventos, noticias y, por supuesto, mostrar sus
                productos y servicios.
                <br />
              </li>
              <li className="listaNote" id="footnote6">
                Tienda en línea
                <br />
                Las tiendas en línea son versiones web de lo que esperarías de
                una tienda real, así que puedes encontrar catálogos de
                productos, promociones, recomendaciones y hasta comentarios de
                otros compradores. Los pagos se hacen a través de tarjetas de
                crédito y los despachos se hacen a través de empresas de
                encomiendas a nivel nacional o internacional.
              </li>
              <li className="listaNote" id="footnote7">
                La memoria RAM del servidor es un factor importante a la hora de
                elegir el hardware para los servidores. Actúa como una memoria
                temporal a corto plazo que procesa los datos desde las unidades
                de disco duro o las unidades de estado sólido hasta la unidad de
                procesamiento central para ofrecer un acceso rápido y fácil.
              </li>
              <li className="listaNote" id="footnote8">
                Los procesadores para servidores son esenciales para que puedan
                ofrecer el mayor rendimiento y que así sus aplicaciones o sitios
                web puedan funcionar de manera óptima, ofreciendo la mejor
                experiencia de usuario.
              </li>

              <li className="listaNote" id="footnote9">
                Los discos SSD (llamados así por el tipo físico) son
                dispositivos de almacenamiento electrónico que utilizan
                circuitos integrados y transistores para almacenar datos en
                ellos.
              </li>
              <li className="listaNote" id="footnote10">
                La transferencia cuenta el total de los datos que son
                intercambiados entre el servidor web y los ordenadores de
                quienes vean tus páginas. Cuantos más datos descargue el
                visitante del servidor, más aumentará la transferencia mensual
                consumida.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export function NotePaginaWeb() {
  return (
    <section className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <ol>
              <li className="listaNote" id="footnote2">
                3 Página en WordPress - dos meses de garantía - 4 días de
                proceso precio 399.000 pesos
              </li>
              <li className="listaNote" id="footnote3">
                5 Página en WordPress - cuatro meses de garantía - 8 días de
                proceso precio 599.000 pesos
              </li>
              <li className="listaNote" id="footnote4">
                10 Página en WordPress - seis meses de garantía - 15 días de
                proceso precio 699.000 pesos
              </li>
              <li className="listaNote" id="footnote5">
                Las páginas internas son una especie de organización jerárquica
                dentro del sitio web, se utilizan para ordenar la información.
              </li>
              <li className="listaNote" id="footnote6">
                WordPress se ha convertido en el CMS líder a nivel mundial. La
                versatilidad que brinda a nivel de diseño web en WordPress, así
                como la facilidad de implementación y gestión de las webs
                realizadas con este CMS son la principal causa de su éxito.
              </li>
              <li className="listaNote" id="footnote7">
                El SEO o posicionamiento orgánico web, es una estrategia de
                marketing digital que tiene como principal objetivo que la
                página web se muestre en las primeras posiciones de los
                buscadores.
              </li>
              <li className="listaNote" id="footnote8">
                El Diseño UX está centrado en la experiencia del usuario y cómo
                se siente este al interactuar con un sistema.
              </li>
              <li className="listaNote" id="footnote9">
                La accesibilidad web tiene como objetivo lograr que las páginas
                web sean utilizables por el máximo número de personas,
                independientemente de sus conocimientos o capacidades personales
                e independientemente de las características técnicas del equipo
                utilizado para acceder a la Web.
              </li>
              <li className="listaNote" id="footnote10">
                Sirve para animar elementos dentro de la página. En concreto,
                permite que el contenido que pongamos dentro de la etiqueta, ya
                sea texto, fotografías, o las dos cosas, se desplace
                horizontalmente por la página.
              </li>
              <li className="listaNote" id="footnote11">
                Se asegura el funcionamiento de los sitios web, tienda Online,
                servidores u otro servicio. No se garantiza el correcto
                funcionamiento de los sistemas/plataformas autoadministrables,
                por cambios no efectuados por profesionales cualificados.
              </li>
              <li className="listaNote" id="footnote12">
                Nuestras páginas web suelen tener un plazo medio de 10 días
                laborales, aunque estos van a depender en gran medida de las
                características de tu proyecto.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export function NoteMantenimiento() {
  return (
    <section className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <ol>
              <li className="listaNote" id="footnote1">
                Asesoría de 1 Horas al día, por  6.900 más IVA
              </li>
              <li className="listaNote" id="footnote2">
                Asesoría de 4 Horas al día, por 27.900 más IVA
              </li>
              <li className="listaNote" id="footnote3">
                Asesoría de 8 horas al día, por 57.900 más IVA
              </li>
              <li className="listaNote" id="footnote4">
                El mantenimiento web engloba todas las acciones preventivas o
                correctivas que se realizan sobre un sitio web para detectar
                errores que estén minando su estructura, usabilidad o
                performance, para así evitar que se deteriore con el tiempo
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export function AyudaMantenimiento() {
  return (
    <div className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <div className="listaNote">
            <h2 className="notetitulo">Mantenimiento web en Antofagasta</h2>
              <p>
                No tener conocimiento sobre internet, no debe frenarte a la hora
                de desarrollar tu negocio y aprovecha la oportunidad que hay. Te
                permitirá olvidarte de los problemas técnicos de tu página web.
                Ya que podrás adquirir un servicio de mantenimiento.
              </p>
              <p>
                Muchos son los beneficios de diseñar una web para tu empresa.
                Para que tu web tenga presencia real en internet, te aconsejamos
                que te dejes guiar por un equipo profesional de desarrollo web.
              </p>
              <p>
                Los clientes te encontrarán y accederán a tu sitio web
                fácilmente Mostrarás tus servicios y/o productos al público de
                manera organizada.
              </p>
              <p>
                Se realizarán copias de seguridad mensual con el objetivo de
                garantizar el funcionamiento ininterrumpido del sitio web.
                Adicionalmente, realizaran actualizaciones de plug-ins, y temas y
                por supuesto, trabajaran en la seguridad de tu sitio web para
                evitar posibles ataques.
              </p>
              <h2 className="notetitulo">Posicionamiento página Antofagasta web</h2>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export function AyudaPaginaWeb() {
  return (
    <div className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <div className="listaNote">
            <h2 className="notetitulo">Diseño de página web Antofagasta</h2>
            <h3 className="notetitulo">Que es una página web</h3>
              <p>
                Una página web es un documento adaptado a internet. Tiene en
                forma de texto, imágenes,videos, etc. Este sitio está formado por
                una serie de sitios web, con los productos que vende, servicios,
                quienes somos, contacto y otros. Lo mas importante, es que una
                web da profesionalismo a tu negocio. Es una gran oportunidad
                para ampliar tu empresa y llegar a un mayor volumen de clientes.
              </p>
              <h3 className="notetitulo">Ventajas de un sitio web</h3>
              <p>
                Tener una web corporativa es como tener un negocio abierto 24
                horas al día todos los días del año. Tus clientes tendrán a
                golpe de clic todo tú catálogo de servicios y productos. Además,
                puedes generar contactos para realizar transacciones comerciales
                y realizar ventas fuera del horario convencional.
              </p>
              <p>
                Con tu web creada con WordPress podrás incluir URL tipo
                preguntas frecuentes, formularios de contacto, página de dudas o
                habilitar un chat en línea para tener un contacto directo con
                tus clientes. Todo esto mejorará la comunicación con tus
                clientes, y te hará mejorar tu atención al mismo.
              </p>
              <p>
                Además, podemos hacer botones de llamada o de
                WhatsApp en la versión móvil. ¡Para que no se te escape ni un
                cliente!
              </p>
              <h3 className="notetitulo">Que es una página web corporativa</h3>
              <p>La página web corporativa es un tipo de web que se utiliza para dar a conocer al público objetivo o clientes potenciales toda la información relacionada con una empresa.</p>
              <h3 className="notetitulo">Desarrollo de páginas web a medida</h3>
              <p>Ofrecemos servicios de diseño de páginas web enfocado en la creación de sitios web personalizados, 
                modernos y funcionales, con la última tecnología, que ayudan a las empresas a destacarse en el mercado. 
                Nuestro equipo profesional de diseñadores web está comprometido a ofrecer un excelente servicio al cliente y productos de alta calidad, 
                adaptados a sus necesidades. Utilizamos herramientas avanzadas para construir sitios web responsivos y optimizados para todos los dispositivos, 
                de forma rápida y sencilla. Ofrecemos servicios de mantenimiento y actualización de sitios web para que su sitio se mantenga actualizado y siempre tenga el mejor rendimiento.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export function AyudaServidor() {
  return (
    <div className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <div className="listaNote">
            <h2 className="notetitulo">Servidor web en Antofagasta</h2>
            <h3 className="notetitulo">Que es un servidor web</h3>
              <p>
                El alojamiento web es un ordenador en el cual están alojados los
                ficheros de tu página web y los datos de tu base de datos.
              </p>
              <p>
                Este ordenador, el cual se le conoce como servidor, es necesario
                para que cuando un cliente busca tu nombre de dominio en su
                navegador, le aparezca tu página web.
              </p>
              <p>
                Como entenderás, es de vital importancia saber gestionar y
                optimizar el servidor en el que está alojada tu website, ya que
                si no, se podrían producir caídas de la web y bajadas de
                rendimiento, lo que para ti se traduce en menos ventas y peor
                experiencia de usuario para tus visitantes.
              </p>
              <p>
                Es por ello que, desde Penseo, configuramos y optimizamos los
                recursos de tu servidor para que siempre esté online y tu página
                web funcione.
              </p>
              <h2 className="notetitulo">Implementación de servidor web a medida</h2>
              <p>
              ofrecemos servicios de servidores en AWS. Nuestro equipo está listo para ayudarte a configurar tu servidor en la nube de Amazon. Tenemos experiencia con todas las herramientas que necesitas para administrar tus servidores de manera eficaz y segura. Estamos aquí para ayudarte con los desafíos de la configuración técnica, así como para proporcionarte asesoramiento en cuanto a la mejor forma de optimizar tu infraestructura para reducir costos.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export function AyudaTiendaOnline() {
  return (
    <div className="BloqueNote">
      <Container>
        <Row className="lineafooter">
          <Col>
            <div className="listaNote">
            <h2 className="notetitulo">Diseño de Tienda online Antofagasta</h2>
            <h3 className="notetitulo">Que es una Tienda online</h3>
              <p>
                Con uso de WordPress nuestro equipo es capaz de desarrollar un
                e-commerce. Te facilitará mucho la vida a la hora de gestionar tu
                tienda online. Ya que una de las principales características del
                uso de WordPress para una tienda online es la facilidad de autogestión.
              </p>
              <p>
                WordPress no tiene por defecto activada la funcionalidad de
                tienda online. Para que el sitio web pueda hacer de tienda
                online se necesita instalar y activar el plug-in de WooCommerce.
                Este famoso plug-in nos permitirá crear un e-commerce 100%
                funcional.
              </p>
              <p>
                El plug-in incluye todo lo que necesitas para tener una tienda
                virtual al completo. Creando la página de carrito, la página de
                área de clientes y la página de finalizar pedido. Además, en
                WooCommerce se pueden integrar plataformas de pago como Flow y
                mercado pago y transferencia bancaria.
              </p>
              <p>
                Por otro lado, con WooCommerce podrás subir todos los productos
                que quieras a tu e-commerce, ¡el número es ilimitado! Con este
                plug-in podremos poner los precios que desees a tus productos,
                así como las descripciones y variaciones de los mismos, como
                colores o tamaños.
              </p>
    
              <p>
                Otra característica de hacer el diseño de tu página web en
                WordPress es que existe otro popular plug-in llamado Yoast SEO.
                Este plug-in es genial para que todo el contenido de tu web esté
                100% optimizado para los motores de búsqueda. Es decir, para que
                tu tienda online se muestre cuando un usuario hace una búsqueda
                relacionada con tu negocio. Esto es fundamental para la
                viabilidad de una web, ya que necesitamos que la web tenga
                tráfico de calidad con clientes que realicen compras.
              </p>
              <h3 className="notetitulo">Que se entiende por comercio electrónico</h3>
              <p>Es una forma, entre otras, de comprar y vender cosas de forma minorista. Algunas empresas sólo venden productos en línea, pero para muchas, el comercio electrónico es un canal de distribución que forma parte de una estrategia más amplia que incluye tiendas físicas y otras fuentes de ingresos.</p>
              <h3 className="notetitulo">Desarrollo de tienda online a medida</h3>
              <p>
              Ofrecemos servicios de tienda online en WordPress. Nuestro equipo de desarrolladores especializados te puede ayudarle a crear una tienda online completamente funcional en WordPress. Ofrecemos servicios de configuración, personalización, diseño y desarrollo para su tienda online de WordPress. Desde la configuración de los métodos de pago hasta la integración de los envíos, nos aseguramos de que su tienda online sea totalmente funcional y segura. Además, ofrecemos servicios de mantenimiento y soporte para su sitio web.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
