import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import videoPaginaweb from "../../video/pagina-web.mp4";
import React from "react";
import "../../styles/banner.css";
import { BtnSecundarioEconomicas } from "../boton/Boton-secundario";

function Bannerpw() {
  return (
    <section>
      <Container>
        <Row>
        <Col xl={6} md={6} sm={12} className="quitarvideo">
            <video
              className="video"
              title="video pagina web"
              src={videoPaginaweb}
              loop
              muted
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
          <div className="BannerServicio">
            <span className="SubTituloGeneral">Haz realidad tus ideas</span>
            <h1 className="TituloGeneral ">Necesita una páginas web empresa</h1>
            <p className="ParrafoGeneral">Con un sitio web puedes dar confianza a tus futuros usuarios.
                                          Digitalizando tu empresa podrás potenciar y posicionar tu establecimiento.</p>
            <span className="Referencia"><span className="ReferenciaValor"> 4,42 mil millones </span>de usuarios conectados a internet</span>
            </div>
            <div className="Bloqueleyenda sombra">
            <p className="parrafo">El 54% de Internet está desarrollado en WordPress</p>
            </div>
            <p className="parrafo">Estás recién comenzando! Empieza con una Página web barata.</p>
            <BtnSecundarioEconomicas />
          </Col>

        </Row>
      </Container>
    </section>
  );
}
export default Bannerpw;
