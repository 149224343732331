import Maletin from "../../svg/maletin.svg";
import Carafeliz from "../../svg/cara-feliz.svg";
import Mail from "../../svg/mail.svg";
import Candado from "../../svg/Candado.svg";
import Imagen from "../../svg/imagen.svg";
import Datos from "../../svg/datos.svg";
import Rediseño from "../../svg/rediseño.svg";
import Velocidad from "../../svg/velocidad.svg";
import Producto from "../../svg/producto.svg";
import Pago from "../../svg/pago.svg";

import wordpress from "../../imagenes/logo/wordpress.webp";
import woocommerce from "../../imagenes/logo/woocommerce.webp";
import tag from "../../imagenes/logo/tag.webp";
import search from "../../imagenes/logo/search.webp";
import hotjar from "../../imagenes/logo/hotjar.webp";
import analitycs from "../../imagenes/logo/analitycs.webp";
import aws from "../../imagenes/logo/aws.webp";
import godaddy from "../../imagenes/logo/godaddy.webp";

import { BtnPortafolio } from "../boton/Boton-secundario";

import { LazyLoadImage } from "react-lazy-load-image-component";
export function Bloqueportafolio() {
    return (
      <>
          <div className="SliderServicio txtservicio">
            <img className="webicono"  src={Maletin} alt="Svg portafolio"  title="portafolio"/>
              <div className="nombreservicios">
                Conoce nuestro <BtnPortafolio />
              </div>
          </div>
      </>
    );
  }
export function Bloqueservicio() {
    return (
      <>
          <div className="ContainerPrncipal txtservicio sombra">
            <img className="webicono"  src={Carafeliz} alt="Svg servicios" title="servicios web" />
              <div className="txtcolor6 nombreservicios">
                Nuestros <span className="txtcolor7">Servicios</span> complementario
              </div>
          </div>
      </>
    );
}
export function Bloquecorreo() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img className="webicono"  src={Mail} alt="Svg mail" title="correo" />
              <h3 className="nombreservicios">
                Tu empresa mas <span className="txtcolor3">profesional</span>
              </h3>
          </div>
      </>
    );
}
export function Bloquesll() {
    return (
      <>
         <div className="SliderServicio txtservicio sombra">
            <img className="webicono"  src={Candado} alt="Svg seguridad" title="seguridad web"/>
              <h3 className="nombreservicios">
                Aumenta la <span className="txtcolor1">seguridad</span> con un certificado sll
              </h3>
          </div>
      </>
    );
}
export function Bloqueimagen() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img className="webicono"  src={Imagen} alt="Svg imagen" title="mejorar tu imagen web" />
              <h3 className="nombreservicios"> 
                <span className="txtcolor5">Mejora</span> tu imagen Corporativa
              </h3>
          </div>
      </>
    );
}
export function Bloquedatos() {
    return (
      <>
         <div className="SliderServicio txtservicio sombra">
            <img  className="webicono" src={Datos} alt="Svg datos" title="almacenar tu información" />
              <h3 className="nombreservicios">
                <span className="txtcolor2"> Datos</span> seguro, organizado y accesible
              </h3>
          </div>
      </>
    );
}
export function Bloquerediseño() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img className="webicono"  src={Rediseño} alt="Svg rediseño" title="diseño web"/>
              <h3 className="nombreservicios">
                La cara de tu negocio como <span className="txtcolor4"> nueva</span>
              </h3>
          </div>
      </>
    );
}
export function Bloquevelocidad() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img className="webicono" src={Velocidad} alt="Svg velocidad" title="pagina web rapidas" />
              <h3 className="nombreservicios">
                Aumenta la <span className="txtcolor3">velocidad</span> de tu WordPress
              </h3>
          </div>
      </>
    );
}
export function Bloqueproducto() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img  className="webicono" src={Producto} alt="Svg productos" title="venta producto online"/>
              <h3 className="nombreservicios">
                Carga de<span className="txtcolor2"> productos web</span> Woocommerce.
              </h3>
          </div>
      </>
    );
}
export function Bloquepago() {
    return (
      <>
          <div className="SliderServicio txtservicio sombra">
            <img className="webicono"  src={Pago} alt="Svg pago" title="medio de pago"/>
              <h3 className="nombreservicios">
                Integración de <span className="txtcolor1"> pasarela de pago</span> Woocommerce.
              </h3>
          </div>
      </>
    );
}
export function Bloqueusuabilidad() {
  return (
    <>
        <div className="SliderServicio txtservicio sombra">
          <img className="webicono"  src={Pago} alt="Svg pago" title="medio de pago"/>
            <h3 className="nombreservicios">
              Mejora la  <span className="txtcolor1"> usuabilidad</span> de tu página web.
            </h3>
        </div>
    </>
  );
}
export function Bloqueseo() {
  return (
    <>
        <div className="SliderServicio txtservicio sombra">
          <img className="webicono"  src={Pago} alt="Svg pago" title="medio de pago"/>
            <h3 className="nombreservicios">
            Mejora tu posicionamiento<span className="txtcolor1"> seo</span> local y web.
            </h3>
        </div>
    </>
  );
}
export function Bloqueseguridad() {
  return (
    <>
        <div className="SliderServicio txtservicio sombra">
          <img className="webicono"  src={Pago} alt="Svg pago" title="medio de pago"/>
            <h3 className="nombreservicios">
            Mejora tu posicionamiento<span className="txtcolor1"> seo</span> local y web.
            </h3>
        </div>
    </>
  );
}

export function Bloquewordpress() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={wordpress} alt="Svg wordpress"  title="wordpress"/>
        </div>
    </>
  );
}
export function Bloquewoocommerce() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={woocommerce} alt="Svg woocommerce" title="woocommerce"/>
        </div>
    </>
  );
}
export function Bloquetag() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={tag} alt="Svg tag maneger" title="tag maneger"/>
        </div>
    </>
  );
}
export function Bloquesearch() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={search} alt="Svg search console" title="search console"/>
        </div>
    </>
  );
}
export function Bloquehotjar() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={hotjar} alt="Svg hotjar" title="hotjar" />
        </div>
    </>
  );
}
export function Bloqueanalitycs() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={analitycs} alt="Svg google analitycs" title="google analitycs"/>
        </div>
    </>
  );
}

export function Bloqueaws() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={aws} alt="Svg aws" title="aws"/>
        </div>
    </>
  );
}

export function Bloquegodaddy() {
  return (
    <>
        <div>
          <LazyLoadImage className="imglogo" src={godaddy} alt="Svg godaddy" title="godaddy"/>
        </div>
    </>
  );
}
