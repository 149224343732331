import "../../styles/planes.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dino1 from "../../video/11.mp4";
import dino2 from "../../video/12.mp4";
import dino3 from "../../video/13.mp4";
import No from "../../svg/x.svg";
import Si from "../../svg/chek.svg";
import { SliderHerramientatienda } from "../Bloques/slider";

function DetalleTiendaOnline() {
  return (
    <section>
      <Container className="bloquedetalle">
        <Row>
          <Col>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th className="columna">Características</th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino1}
                          loop
                          muted
                        />
                      </div>
                      Estándar
                    </th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino2}
                          loop
                          muted
                        />
                      </div>
                      Plus
                    </th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino3}
                          loop
                          muted
                        />
                      </div>
                      Avanzado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="linea">
                    <td className="nombrePlantabla">
                      Páginas Internas
                      <sup>
                        <a
                          aria-label="Nota 6 ¿Catidad de página interna"
                          className="footnote"
                          href="#footnote1"
                          title="Páginas Internas"
                        >
                          1
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">5</td>
                    <td className="boxdatos">8</td>
                    <td className="boxdatos fondotabla">10</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Tipo de páginas
                      <sup>
                        <a
                          aria-label="Nota 6 ¿Tipo de tienda online?"
                          className="footnote"
                          href="#footnote2"
                          title="¿Tipo de tienda online?"
                        >
                          2
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">WordPress</td>
                    <td className="boxdatos">WordPress</td>
                    <td className="boxdatos fondotabla">WordPress</td>
                  </tr>

                  <tr>
                    <td className="nombrePlantabla">
                      Cantida de productos
                      <sup>
                        <a
                          aria-label="Nota 6 ¿Cantidad de producto?"
                          className="footnote"
                          href="#footnote3"
                          title="¿Cantidad de producto?"
                        >
                          3
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">70</td>
                    <td className="boxdatos">100</td>
                    <td className="boxdatos fondotabla">150</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                    Plazo de Entrega - dias
                      <sup>
                        <a
                          aria-label="Nota 6 ¿plazo de entrega?"
                          className="footnote"
                          href="#footnote4"
                          title="¿plazo de entrega?"
                        >
                          4
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">6</td>
                    <td className="boxdatos">10</td>
                    <td className="boxdatos fondotabla">20</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Seo
                      <sup>
                        <a
                          aria-label="Nota 6 ¿Que es seo?"
                          className="footnote"
                          href="#footnote5"
                          title="¿Que es seo?"
                        >
                          5
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">
                      <img src={No} alt="Svg No" title="no icono"/>
                    </td>
                    <td className="boxdatos">
                      <img src={No} alt="Svg No" title="no icono"/>
                    </td>
                    <td className="boxdatos fondotabla">
                      <img src={Si} alt="Svg Si" title="si icono" />
                    </td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">Garantia</td>
                    <td className="boxdatos fondotabla1">2 Meses</td>
                    <td className="boxdatos fondotabla">4 Meses</td>
                    <td className="boxdatos fondotabla1">5 Meses</td>
                  </tr>
                  <tr className="linea2">
                    <td className="nombrePlantabla">Precio</td>
                    <td className="boxdatos fondotabla">$499.000</td>
                    <td className="boxdatos">$699.000</td>
                    <td className="boxdatos fondotabla">$799.000</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>*IMPORTANTE: Ninguno de nuestros planes considera la creación ni edición de contenidos.</p>
          </Col>
          <Col>
            <SliderHerramientatienda/>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DetalleTiendaOnline;
