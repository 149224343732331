import "../../styles/planes.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dino1 from "../../video/11.mp4";
import dino2 from "../../video/12.mp4";
import dino3 from "../../video/13.mp4";
import { SliderHerramientaservidores } from "../Bloques/slider";
import Info from "../../svg/info.svg";


function DetalleServidor() {
  return (
    <section>
      <Container className="bloquedetalle">
        <Row>
          <Col sm={12}>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th className="columna">Características</th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino1}
                          loop
                          muted
                        />
                      </div>
                      Estándar
                      <sup>
                        <a
                          aria-label="Nota 1 - Plan estandar"
                          className="footnote"
                          href="#footnote1"
                          title=" plan estandar"
                        >
                          1
                        </a>
                      </sup>
                    </th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino2}
                          loop
                          muted
                        />
                      </div>
                      Plus
                      <sup>
                        <a
                          aria-label="Nota 2 - plan plus"
                          className="footnote"
                          href="#footnote2"
                          title="plan plus"
                        >
                          2
                        </a>
                      </sup>
                    </th>
                    <th>
                      <div className="boxvideo">
                        <video
                          className="video2"
                          src={dino3}
                          loop
                          muted
                        />
                      </div>
                      Avanzado
                      <sup>
                        <a
                          aria-label="Nota 3 - Plan avanzado"
                          className="footnote"
                          href="#footnote3"
                          title="plan avanzado"
                        >
                          3
                        </a>
                      </sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="linea">
                    <td className="nombrePlantabla">Tipo de pagina</td>
                    <td className="boxdatos fondotabla">
                      Informativa
                      <sup>
                        <a
                          aria-label="Nota 4 ¿que es una página informativa?"
                          className="footnote"
                          href="#footnote4"
                          title="¿que es una página informativa?"
                        >
                          4
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos">
                      Corporativa
                      <sup>
                        <a
                          aria-label="Nota 5 ¿que es una página corporativa?"
                          className="footnote"
                          href="#footnote4"
                          title="¿que es una página corporativa?"
                        >
                          5
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">
                      Tienda Online
                      <sup>
                        <a
                          aria-label="Nota 6 ¿que es una tienda online?"
                          className="footnote"
                          href="#footnote6"
                          title="¿que es una tienda online?"
                        >
                          6
                        </a>
                      </sup>
                    </td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Ram
                      <sup>
                        <a
                          aria-label="Nota 7 ¿que es una memoria ram?"
                          className="footnote"
                          href="#footnote7"
                          title="¿que es una memoria ram?"
                        >
                          7
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">512 MB</td>
                    <td className="boxdatos">1 GB</td>
                    <td className="boxdatos fondotabla">2 GB</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Procesador
                      <sup>
                        <a
                          aria-label="Nota 8 ¿que es un procesador?"
                          className="footnote"
                          href="#footnote8"
                          title="¿que es un procesador?"
                        >
                          8
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">1 Núcleo</td>
                    <td className="boxdatos">1 Núcleo</td>
                    <td className="boxdatos fondotabla">1 Núcleo</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Capacidad SSD
                      <sup>
                        <a
                          aria-label="Nota 9 ¿que es una tarjeta SSD?"
                          className="footnote"
                          href="#footnote9"
                          title="¿que es una tarjeta SSD?"
                        >
                          9
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">20 GB</td>
                    <td className="boxdatos">40 GB</td>
                    <td className="boxdatos fondotabla">60 GB</td>
                  </tr>
                  <tr>
                    <td className="nombrePlantabla">
                      Tranferencia
                      <sup>
                        <a
                          aria-label="Nota 10 ¿que es la transferencia de datos en un servidor web?"
                          className="footnote"
                          href="#footnote10"
                          title="¿que es la transferencia de datos en un servidor web?"
                        >
                          10
                        </a>
                      </sup>
                    </td>
                    <td className="boxdatos fondotabla">1 TB</td>
                    <td className="boxdatos">2 TB</td>
                    <td className="boxdatos fondotabla">3 TB</td>
                  </tr>
                  <tr className="linea2">
                    <td className="nombrePlantabla">Precio</td>
                    <td className="boxdatos fondotabla">$54.990</td>
                    <td className="boxdatos">$78.990</td>
                    <td className="boxdatos fondotabla">$153.000</td>
                  </tr>

                </tbody>
              </table>
              <p className="Informacion">
            <img src={Info} alt="Svg info" title="mas informción servidores" />
            Todos los planes incluyen WordPress instalado y Respaldo
          </p>
            </div>
          </Col>
          <Col sm={12}>
            <SliderHerramientaservidores/>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DetalleServidor;
