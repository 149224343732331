import "../index.css";
import SubOservicios from "../componentes/submenus/SubOservicios";
import Banners from "../componentes/BannerPaginas/Banner-S";
import SubFooter from "../componentes/submenus/SubFooter";
import ServidorBloque from "../componentes/Bloques/servidor";
import { AyudaServidor } from "../componentes/Bloques/Note";
import React from 'react';
import Helmet from "react-helmet";
function OServicio() {
  return (
    <>
      <Helmet>
        <title>Servidores web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Empresa Antofagastina de servicios web y Seo. Servidores seguro y escalable garantizan la mejor experiencia para tus clientes."
        />
      </Helmet>
      <SubOservicios />
      <Banners />
      <ServidorBloque />
      <AyudaServidor />
      <SubFooter />
    </>
  );
}
export default OServicio;
