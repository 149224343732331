import SubOservicios from "../../componentes/submenus/SubOservicios";
import DetalleServidor from "../../componentes/Planes/DetalleServidor";
import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import { NoteServidor } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function EOservicio() {
  return (
    <>
      <Helmet>
        <title>Especicicaciones de Servidores web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Conoce nuestros diferentes planes de servidores web. Desprecupate de la implementacion de tu página web, usamos la mejor tecnologia del mercado"
        />
      </Helmet>
      <SubOservicios />
      <DetalleServidor />
      <Clientes />
      <NoteServidor />
      <SubFooter />
    </>
  );
}
export default EOservicio;
