import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BtnVolverInicio } from "../boton/Boton-secundario";
import { NavLink } from "react-router-dom";

function SubPaginaWeb() {
  return (
    <div className="submenu">
      <Navbar>
        <Container>
          <BtnVolverInicio/>
          <Navbar.Brand className="mobilsubmenu">Web</Navbar.Brand>
          <Navbar.Toggle />

          <Navbar.Collapse className="justify-content-end">
          <NavLink
                className="BtnSubMenu BtnDefault"
                to="/caso-exito"
                aria-label="Caso de exito"
                title="casos de exito"
              >
                Casos de exito
              </NavLink>
            <a
                      title="cotizar web"
            aria-label="Cotizar servicios" 
            className="BtnSubMenu2 BtnDefault"
            href="https://penseo.odoo.com/contacto">
            Cotizar
            </a>
          </Navbar.Collapse>
          
        </Container>
      </Navbar>
    </div>
  );
}

export default SubPaginaWeb;
