import './App.css';
import React from "react";
import Inicio from './Paginas/Inicio';
import Layout from './componentes/Layout';
import Pweb from './Paginas/pagina-web';
import { Route, Routes,Navigate } from 'react-router-dom';
import Cexito from './Paginas/casos-de-exito';
import Tonline from './Paginas/tienda-online';
import OServicio from './Paginas/otros-servicios';
import Mantenimiento from './Paginas/Mantenimiento-web';
import ETonline from './Paginas/Especificaciones/E-tienda-online';
import EMantenimiento from './Paginas/Especificaciones/E-Mantenimiento-web';
import EOservicio from './Paginas/Especificaciones/E-otros-servicios';
import EPweb from './Paginas/Especificaciones/E-pagina-web';
import Ayuda from './Paginas/Ayuda';
import PrecioTiendaOnline from './Paginas/Precios/PreciosTiendaOnline';
import PrecioPaginaweb from './Paginas/Precios/PreciosPaginaWeb';
import PrecioMantenimiento from './Paginas/Precios/PrecioMantenimiento';
import PrecioServidor from './Paginas/Precios/PrecioServidores';
import WebEconomicas from './Paginas/WebEconomicas';
import PrecioWebEconomicas from './Paginas/Precios/PrecioWebEconomicas';
import EWebEconomicas from './Paginas/Especificaciones/E-WebEconomicas';


import Web from './Paginas/web';
import PcMac from './Paginas/pc-mac';



function App() {
  return (
    <>
          <Routes>
              <Route path='/' element={<Layout/>}>
                <Route index element={<Inicio/>}/>

                <Route path='caso-exito' element={<Cexito/>}/>

                <Route path='tienda-online' element={<Tonline/>}/>
                <Route path='especificaciones-tienda-online' element={<ETonline/>}/>
                <Route path='precio-tienda-online' element={<PrecioTiendaOnline/>}/>

                <Route path='pagina-web' element={<Pweb/>}/>
                <Route path='especificaciones-pagina-web' element={<EPweb/>}/>
                <Route path='precio-paginaWeb' element={<PrecioPaginaweb/>}/>

                <Route path='otro-servicio' element={<OServicio/>}/>
                <Route path='especificaciones-otros-servivio' element={<EOservicio/>}/>
                <Route path='precio-servidor' element={<PrecioServidor/>}/>

                <Route path='mantenimiento' element={<Mantenimiento/>}/>
                <Route path='especificaciones-manteniminto' element={<EMantenimiento/>}/>
                <Route path='precio-mantenimiento' element={<PrecioMantenimiento/>}/>

                <Route path='web' element={<Web/>}/>
                <Route path='pc-mac' element={<PcMac/>}/>

                <Route path='web-economicas' element={<WebEconomicas/>}/>
                <Route path='precio-web-economicas' element={<PrecioWebEconomicas/>}/>
                <Route path='especificaciones-web-economicas' element={<EWebEconomicas/>}/>

                <Route path='ayuda' element={<Ayuda/>}/>

                <Route path='*' element={<Navigate replace to='/'/>}/>
              </Route>
          </Routes>
          
    </>
  )
}

export default App;

