import "../index.css";
import SubTonine from "../componentes/submenus/SubTonline";
import Bannerto from "../componentes/BannerPaginas/Banner-to";
import Vendelinea from "../componentes/Bloques/Vende-linea";
import SubFooter from "../componentes/submenus/SubFooter";
import { AyudaTiendaOnline } from "../componentes/Bloques/Note";
import SubOferta from "../componentes/submenus/SubOferta";
import React from 'react';
import Helmet from "react-helmet";
function Tonline() {
  return (
    <>
    <Helmet>
      <title>Diseño de tienda online Antofagasta - Penseo.cl</title>
      <meta
          name="description"
          content="creación de una tienda online consiste en ofrecer una variedad de herramientas y recursos que permitan a las empresas tener una presencia en línea. permiten a los usuarios a cargar productos, gestionar inventarios, procesar pagos, crear ofertas y promociones, diseñar contenido y mucho más."
        />
    </Helmet>
      <SubTonine />
      <Bannerto />
      <Vendelinea />
      <SubOferta/>
      <AyudaTiendaOnline/>
      <SubFooter />
    </>
  );
}
export default Tonline;
