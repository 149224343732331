
export function Recursos() {
  return (
    <div className="BloqueCenter BloqueTitulo">
      <span className="SubTituloGeneral">
        Precios sencillos, económico y escalables.
      </span>
      <h2 className="TituloGeneral">Los mejores planes para tu negocio</h2>
      <p className="ParrafoGeneral">
        Potenciamos su imagen e identidad corporativa con un diseño atractivo y
        funcional.
      </p>
    </div>
  );
}

