import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import PlanesMantenimineto from "../../componentes/Planes/PlanesMantenimineto";
import SubMantenimineto from "../../componentes/submenus/SubMantenimineto";
import { AyudaMantenimiento } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function PaginaMantenimiento() {
  return (
    <>
      <Helmet>
        <title>Precios de página web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Los precios de los servicios de mantenimiento de páginas web dependen de varios factores, como el tamaño y la complejidad del sitio web, los recursos requeridos para su construcción, las características y funcionalidades deseadas y el tiempo de entrega."
        />
      </Helmet>
      <SubMantenimineto />
      <PlanesMantenimineto />
      <Clientes />
      <SubFooter />
      <AyudaMantenimiento/>
    </>
  );
}
export default PaginaMantenimiento;
