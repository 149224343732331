import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import ImgPaginaweb from "../../imagenes/paginaweb.webp";
import ImgMantenimiento from "../../imagenes/mantenimiento.webp";
import ImgTiendaonline from "../../imagenes/tiendaonline.webp";
import Imglowcost from "../../imagenes/lowcost.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

gsap.registerPlugin(ScrollTrigger);
function Tiposweb() {
  useEffect(() => {
    gsap.to(".web", {
      scrollTrigger: {
        scrub: 1,
      },

      scale:2.6,
    });

    ScrollTrigger.refresh();
  });
  return (
    <>
      <section>
      <h1 className="tituloweb web">Web</h1>
        <Container>
          <Row className="g-3" >
            <Col sm={6} xl={3}>
              <LazyLoadImage
                className="imgWeb"
                src={ImgTiendaonline}
                alt="Tienda online"
                title="Tienda online"
                effect='blur'
              />
                <NavLink
                  className="BtnTipoweb BtnDefault"
                  to="/tienda-online"
                  aria-label="Tienda online"
                  title="ver tienda online"
                >
                  Tienda Online
                </NavLink>
            </Col>

            <Col sm={6} xl={3}>
            <LazyLoadImage
                className="imgWeb"
                src={Imglowcost}
                alt="web economicas"
                title="web economicas"
                effect='blur'
              />
              <NavLink
                className="BtnTipoweb BtnDefault"
                to="/web-economicas"
                aria-label="web economicas"
                title="ver web economicas"
              >
                Low cost
              </NavLink>
            </Col>
            <Col sm={6} xl={3}>
            <LazyLoadImage
                className="imgWeb"
                src={ImgMantenimiento}
                alt="mantenimiento web"
                title="mantenimiento web"
                effect='blur'
              />
              <NavLink
                className="BtnTipoweb BtnDefault"
                to="/mantenimiento"
                aria-label="Mantenimiento web"
                title="ver mantenimiento web"
              >
                Mantenimiento
              </NavLink>
            </Col>
            <Col sm={6} xl={3}>
            <LazyLoadImage
                className="imgWeb"
                src={ImgPaginaweb}
                alt="Paginas web"
                title="Paginas web"
                effect='blur'
              />
              <NavLink
                className="BtnTipoweb BtnDefault"
                to="/pagina-web"
                aria-label="Pagina web"
                title="ver página web"
              >
                Pagina Web
              </NavLink>
            </Col>
          </Row>
        </Container>

        <div className="patronWeb">
            <svg width="100%" height="100%">
              <pattern
                id="patron-circulo"
                x="0"
                y="0"
                width="40"
                height="40"
                patternUnits="userSpaceOnUse"
              >
                <circle cx="12" cy="12" r="12" fill="#edeff1"></circle>
              </pattern>

              <rect
                id="rect"
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#patron-circulo)"
              ></rect>
            </svg>
          </div>
      </section>
        <Container>
          <Row className="g-2">
            <Col sm={12} xl={12}>
              <div className="BloqueCenter BloqueTitulo">
                <span className="SubTituloGeneral">
                  Precios sencillos, económico y escalables.
                </span>
                <h2 className="TituloGeneral">¿NECESITAS UNA PÁGINA WEB?</h2>
                <p>
                ¿Necesitas mejorar tu presencia en línea? ¡Bienvenido! Estamos aquí para ayudar.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
    </>
  );
}
export default Tiposweb;
