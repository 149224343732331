import SubMantenimineto from "../../componentes/submenus/SubMantenimineto";
import DetalleMantenimiento from "../../componentes/Planes/DetalleMantenimineto";
import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import ListaServicios2 from "../../componentes/Bloques/ListaServicios2";
import { NoteMantenimiento } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function EMantenimiento() {
  return (
    <>
      <Helmet>
        <title>Especificaciones mantenimeinto web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Conoce nuestros diferentes planes de mantenimiento web, te ayudaremos a mejorar tu rendimiento y posicionamiento de tu sitio o tienda online Penseo.cl"
        />
      </Helmet>
      <SubMantenimineto />
      <DetalleMantenimiento />
      <ListaServicios2 />
      <Clientes />
      <NoteMantenimiento />
      <SubFooter />
    </>
  );
}
export default EMantenimiento;
