import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BtnVolverArriba, BtnVolverInicio } from "../boton/Boton-secundario";

function SubFooter() {
  return (
    <Navbar className="ColorGriss">
      <Container>
        <BtnVolverInicio/>
        <Navbar.Brand className="mobilsubmenu">Penseo.cl</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <BtnVolverArriba/>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SubFooter;
