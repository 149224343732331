import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import ecoasis from "../../imagenes/clientes/ecoasis.webp";
import fissalis from "../../imagenes/clientes/fisalis.webp";
import glauben from "../../imagenes/clientes/glauben.webp";
import dentista from "../../imagenes/clientes/dentista.webp";
import lentes from "../../imagenes/clientes/lentes.webp";
import cosan from "../../imagenes/clientes/cosan.webp";
import advanteje from "../../imagenes/clientes/advetajes.webp";
import { BtnSecundarioClientes } from "../boton/Boton-secundario";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Clientes = () => {
  return (
    <section>
      <div className="BloqueCenter BloqueTitulo">
        <span className="SubTituloGeneral">Nuevo Proyectos</span>
        <div className="TituloGeneral">Casos de éxito</div>
        <p>Nuestra mayor satisfacción es ver a clientes satisfechos.</p>
        <BtnSecundarioClientes/>
      </div>
      <Swiper
        slidesPerView={5}
        spaceBetween={10}
        slidesPerGroup={3}
        loop={true}
        loopFillGroupWithBlank={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 1,
            slidesPerGroup: 1,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 1,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 1,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 1,
            slidesPerGroup: 3,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 1,
            slidesPerGroup: 3,
          },
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={ecoasis} alt="ecoasis"  title="ecoasis"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={fissalis} alt="fissalis" title="fissalis"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={glauben} alt="glauben" title="glauben"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={dentista} alt="dentista" title="dentista"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={lentes} alt="lentes" title="lentes"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={cosan} alt="cosan" title="cosan"/>
        </SwiperSlide>
        <SwiperSlide>
          <LazyLoadImage className="imgcliente" src={advanteje} alt="adventaje" title="adventaje"/>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Clientes;
