import SubTonine from "../../componentes/submenus/SubTonline";
import SubFooter from "../../componentes/submenus/SubFooter";
import PlanesTiendaOnline from "../../componentes/Planes/PlanesTiendaOnline";
import Clientes from "../../componentes/Bloques/Clientes";
import { AyudaTiendaOnline } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function TiendaOnline() {
  return (
    <>
      <Helmet>
        <title>Precios tienda online antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Los precios de los servicios de tienda online dependen de varios factores, como el tamaño y la complejidad del sitio web, los recursos requeridos para su construcción, las características y funcionalidades deseadas y el tiempo de entrega."
        />
      </Helmet>
      <SubTonine />
      <PlanesTiendaOnline />
      <Clientes />
      <SubFooter />
      <AyudaTiendaOnline/>
    </>
  );
}
export default TiendaOnline;
