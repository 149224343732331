import "../index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubAyuda from "../componentes/submenus/SubAyuda";

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import wordpress from "../../src/svg/wordpress.svg";
import woocommerce from "../../src/svg/woocommerce.svg";
import help from "../../src/svg/help.svg";
import mail2 from "../../src/svg/mail2.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { BotonTicket } from "../componentes/boton/Boton-secundario";
import SubFooter from "../componentes/submenus/SubFooter";

import Helmet from "react-helmet";
function Ayuda() {
  return (
    <>
      <Helmet>
        <title>Ayuda pagina web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Te ayudamos a manejar tu página con tips y consejos. te ayudamos si presentas algun problema con tu sitio web "
        />
      </Helmet>
      <SubAyuda />
      <section>
        <Container>
          <Row>
            <Col>
              <section>
                <h1 className="TituloGeneral">Ticket de ayuda</h1>
                <p className="ParrafoGeneral">
                  Potenciamos tu negocio con una página web o tienda online,
                  moderna, atractiva y al mejor precio.
                </p>
                <div className="spaceboton">
                  <BotonTicket />
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </section>
      <SubFooter />
    </>
  );
}
export default Ayuda;
