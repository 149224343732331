import ListaServicios from "../../componentes/Bloques/ListaServicios";
import DetalleTiendaOnline from "../../componentes/Planes/DetalleTiendaOnline";
import SubFooter from "../../componentes/submenus/SubFooter";
import Clientes from "../../componentes/Bloques/Clientes";
import { NoteTiendaonline } from "../../componentes/Bloques/Note";
import SubWebEconomicas from "../../componentes/submenus/SubWebEconomicas";
import Helmet from "react-helmet";
function EWebEconomicas() {
  return (
    <>
      <Helmet>
        <title>Especificaciones web economicas antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Conoce nuestros diferentes planes de web economicas, empieza a digitalizar tu empresa con un sitio basico y funcional."
        />
      </Helmet>
      <SubWebEconomicas />
      <DetalleTiendaOnline />
      <ListaServicios />
      <Clientes />
      <NoteTiendaonline />
      <SubFooter />
    </>
  );
}
export default EWebEconomicas;
