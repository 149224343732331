import React from "react";
import { SliderTiendaOnline } from "./slider";

function Vendelinea() {
  return (
    <section className="ColorGriss">
      <div className="BloqueTitulo Bloqueespacio">
        <h2 className="TituloGeneral">
          <span className="TituloSecundario">TIENDA ONLINE</span> AUTO
          ADMINISTRABLE
        </h2>
        <p className="ParrafoGeneral">
          Sabemos la importancia de tener una tienda online. Por eso nos
          especializamos en el diseño y desarrollo de WordPress para Ecommerce.
          De hecho, no es más que una página web con la funcionalidad de tienda,
          quien te permitirá mostrar tu catálogo de productos a tus clientes en
          Internet.
        </p>
      </div>
      <SliderTiendaOnline />
    </section>
  );
}
export default Vendelinea;
