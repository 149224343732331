import React from "react";
import { SliderServidor } from "./slider";

function ContainerServicios() {
  return (
    <section>
      <div className="BloqueTitulo Bloqueespacio">
        <h2 className="TituloGeneral">Transformación digital</h2>
        <p className="ParrafoGeneral">
                  Somos conscientes de la importancia de una buena presencia en
                  línea, así que nos aseguramos de que todos nuestros diseños
                  sean SEO amigable para ayudar a su sitio web a obtener un
                  buen posicionamiento en los motores de búsqueda y para que su
                  público objetivo pueda encontrarlos fácilmente.
                </p>
      </div>
      <SliderServidor />
    </section>
  );
}
export default ContainerServicios;
