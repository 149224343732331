import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../styles/planes.css";
import Hora from "../../svg/hora.svg";
import {BtnLandingPlanesAvanzado, BtnPWEcoPlanesEstandar, BtnTOEcoPlanesPlus } from "../boton/Boton-secundario";

function PlanesWebEconomica() {
  return (
    <div >
      <div className="fondoplanes"></div>
      <Container>
        <Row className="g-0">
          <Col sm={4}>
            <article>
              <div className="cajaplan">
                <h2 className="plan ">Landing page</h2>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>99.000
                </span>
                <span className="ModoPago colorgriss">/Pago Unico</span>
                <span className="lineabtn"></span>

                <span className="descripcion colorgriss">
                 Es un mini sitio web que esta todos tus servicios en una página.
                </span>
                <p className="hora">
                  <img src={Hora} alt="Svg hora" title="hora icono"/> 6 días
                </p>
                <span className="lineabtn"></span>
                <BtnLandingPlanesAvanzado/>
              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
              <div className="cajaplan">
                <h2 className="plan ">Página web</h2>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>149.000
                </span>
                <span className="ModoPago colorgriss">/Pago Unico</span>
                <span className="lineabtn"></span>
                <p className="descripcion colorgriss">
                  Ideal para sitios web corporativos, te ayuda a fidelización de tus clientes de forma online.
                </p>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="hora icono"/> 10 días
                </span>
                <span className="lineabtn"></span>
                <BtnPWEcoPlanesEstandar/>

              </div>
            </article>
          </Col>
          <Col sm={4}>
            <article>
              <div className="cajaplancenter">
                <h2 className="plan ">Tienda online</h2>
                <span className="linea"></span>
                <span className="precio">
                  <span className="valorsimbolo">$</span>199.000
                </span>
                <span className="ModoPago colorgriss">/Pago Unico</span>
                <span className="lineabtn"></span>
                <p className="descripcion">
                  Ideal para entrar al comercio electronicon con una tienda online.
                </p>
                <span className="hora">
                  <img src={Hora} alt="Svg hora" title="hora icono"/> 20 días
                </span>
                <span className="lineabtn"></span>
                <BtnTOEcoPlanesPlus/>
              </div>
            </article>
          </Col>
        </Row>
 
      </Container>  
      <div className="patron">
      <svg  width="100%" height="240px">
      <pattern id="patron-circulo" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
        <circle cx="12" cy="12" r="12" fill="#edeff1"></circle>
      </pattern>

      <rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#patron-circulo)"></rect>
    </svg>
      </div>
    </div>
  );
}

export default PlanesWebEconomica;
