import "../index.css";
import TiposWeb from "../componentes/Bloques/TiposWeb";
import SubFooter from "../componentes/submenus/SubFooter";
import SubPaginaWeb from "../componentes/submenus/SubWeb";
import React from 'react';
import Helmet from "react-helmet";

function web() {
  return (
    <>
    <Helmet>
      <title>Servicios de dersarrollo web antofagasta - Penseo.cl</title>‍
      <meta name="description" content="conoce nuestros diferentes planes de página web, que te ayudaran a mantener o digitalizar tu empresa de servicio u tienda online " />‍
    </Helmet>
      <SubPaginaWeb/>
      <TiposWeb />
      <SubFooter />
    </>
  );
}
export default web;
