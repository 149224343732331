import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Outlet, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Tippy from "@tippyjs/react";
import { BotonCorreofooter, Botoninstafooter } from "./boton/Boton-secundario";
import Badge from 'react-bootstrap/Badge';




function layout() {
  return (
    <>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand id="volverinicio" aria-label="bienvenido a penseo">
              Penseo
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavLink
                  className="BtnMenu BtnDefault"
                  to="/Web"
                  aria-label="Web"
                  title="web"
                >
                  Web
                </NavLink>
                <NavLink
                  className="BtnMenu BtnDefault"
                  to="/otro-servicio"
                  aria-label="Servidores web"
                  title="servidor web"
                >
                  Servidores
                </NavLink>
                <a
                  className="BtnMenu BtnDefault"
                  href="https://tienda.penseo.cl/shop/category/soporte-tecnico-7"
                  aria-label="Pc / Mac"
                  title="Pc / Mac"
                >
                  Pc / Mac <Badge bg="secondary">Soporte técnico</Badge>
                </a>
              </Nav>
              <Nav>
                <Tippy content="ingreso a ayuda">
                  <NavLink
                    className="BtnMenu BtnMovil"
                    to="/ayuda"
                    aria-label="ayuda"
                    title="ayuda"
                  >
                    <svg
                      width="24px"
                      height="24px"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      color="#fff"
                    >
                      <path
                        d="M20 11a8 8 0 10-16 0"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M2 15.438v-1.876a2 2 0 011.515-1.94l1.74-.436a.6.6 0 01.745.582v5.463a.6.6 0 01-.746.583l-1.74-.435A2 2 0 012 15.439zM22 15.438v-1.876a2 2 0 00-1.515-1.94l-1.74-.436a.6.6 0 00-.745.582v5.463a.6.6 0 00.745.583l1.74-.435A2 2 0 0022 15.439zM20 18v.5a2 2 0 01-2 2h-3.5"
                        stroke="#fff"
                        stroke-width="1.5"
                      ></path>
                      <path
                        d="M13.5 22h-3a1.5 1.5 0 010-3h3a1.5 1.5 0 010 3z"
                        stroke="#fff"
                        stroke-width="1.5"
                      ></path>
                    </svg>
                  </NavLink>
                </Tippy>
                <Tippy content="ingreso a tienda">
                  <a
                    className="BtnMenu BtnMovil"
                    href="https://tienda.penseo.cl/shop"
                    aria-label="tienda"
                    title="tienda"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <circle cx="80" cy="216" r="16"></circle>
                      <circle cx="184" cy="216" r="16"></circle>
                      <path
                        d="M42.3,72H221.7l-26.4,92.4A15.9,15.9,0,0,1,179.9,176H84.1a15.9,15.9,0,0,1-15.4-11.6L32.5,37.8A8,8,0,0,0,24.8,32H8"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="16"
                      ></path>
                    </svg>
                  </a>
                </Tippy>
                <a
                  className="BtnMenu BtnDefault"
                  href="https://tienda.penseo.cl/blog/desarrollo-web-1"
                  title="blog"
                >
                  Blog
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <Outlet />
      <footer className="BloqueFooter">
        <Container>
          <Row>
            <Col xl={3} md={3} sm={4}>
              <span className="SubTituloSecundario">Contactanos</span>
              <div className="ContenedorFooter">
                <ul>
                  <li className="BtnDefault iconopos">
                    <Botoninstafooter />
                  </li>
                  <li className="BtnDefault iconopos">
                    <BotonCorreofooter />
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={3} md={3} sm={4}>
              <ul
                role="menubar"
                aria-activedescendant="servicios"
                id="offeringsa"
                aria-describedby="navhowto"
                tabindex="0"
              >
                <li className="BtnDefault" role="menuitem">
                  <Link
                    className="BtnMenu-footer BtnDefault"
                    to="/tienda-online"
                    title="tienda online"
                  >
                    Tienda online
                  </Link>
                </li>
                <li className="BtnDefault" role="menuitem">
                  <Link className="BtnMenu-footer BtnDefault" to="/pagina-web" title="página web">
                    Pagina web
                  </Link>
                </li>
                <li className="BtnDefault" role="menuitem">
                  <Link
                    className="BtnMenu-footer BtnDefault"
                    to="/otro-servicio"
                    title="servidor web"
                  >
                    Servidores web
                  </Link>
                </li>
                <li className="BtnDefault" role="menuitem">
                  <Link
                    className="BtnMenu-footer BtnDefault"
                    to="/mantenimiento"
                    title="mantenimiento web"
                  >
                    Mantenimiento web
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xl={3} md={3} sm={4}>
              <ul
                role="menubar"
                aria-activedescendant="pagina"
                id="offeringsB"
                aria-describedby="navhowto"
                tabindex="0"
              >
                <li className="BtnDefault" role="menuitem">
                  <a
                    className="BtnMenu-footer BtnDefault"
                    href="https://blog.penseo.cl/"
                    title="blog footer"
                  >
                    Blog
                  </a>
                </li>
                <li className="BtnDefault" role="menuitem">
                  <Link className="BtnMenu-footer BtnDefault" to="/ayuda" title="ayuda footer">
                    Ayuda
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xl={3} md={3} sm={0}>
              <span className="ParrafoGeneral">Antofagasta, Chile</span>
              <div className="ContenedorFooter">
                <span className="SubTituloSecundario">
                  ¡Hola 👋! ¿Te ayudo?
                </span>
              </div>
              <div className="ContenedorFooter">
                <svg
                  width="48px"
                  height="48px"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  color="#1d1d1f"
                >
                  <path
                    d="M13.25 19.25l3.5-3.5-3.5-3.5"
                    stroke="#1d1d1f"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M16.75 15.75h-6a4 4 0 01-4-4v-7"
                    stroke="#1d1d1f"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
  </>
  );
}

export default layout;


