import SubFooter from "../../componentes/submenus/SubFooter";
import PlanesPaginaweb from "../../componentes/Planes/PlanesPaginaWeb";
import Clientes from "../../componentes/Bloques/Clientes";
import SubPweb from "../../componentes/submenus/SubPweb";
import { AyudaPaginaWeb } from "../../componentes/Bloques/Note";
import Helmet from "react-helmet";
function PaginaWeb() {
  return (
    <>
      <Helmet>
        <title>Precios de página web antofagasta - Penseo.cl</title>
        <meta
          name="description"
          content="Los precios de los servicios de páginas web dependen de varios factores, como el tamaño y la complejidad del sitio web, los recursos requeridos para su construcción, las características y funcionalidades deseadas y el tiempo de entrega."
        />
      </Helmet>
      <SubPweb />
      <PlanesPaginaweb />
      <Clientes />
      <SubFooter />
      <AyudaPaginaWeb/>
    </>
  );
}
export default PaginaWeb;
