import "../index.css";
import SubFooter from "../componentes/submenus/SubFooter";
import SubOferta from "../componentes/submenus/SubOferta";
import SubWebEconomicas from "../componentes/submenus/SubWebEconomicas";
import BannerWebEconomicas from "../componentes/BannerPaginas/BannerWebEconomicas";
import React from 'react';
import Helmet from "react-helmet";
function WebEconomicas() {
  return (
    <>

    <Helmet>
      <title>Páginas web economicas en antofagasta - Penseo.cl</title>‍
      <meta name="description" content="Comienza con sitio web economico, te ayudara a mejorar tus posicionamiento en internet. dando a conocer tu empresa de servicio o tienda online" />‍
    </Helmet>
      <SubWebEconomicas />
      <BannerWebEconomicas />
      <SubOferta />
      <SubFooter />
    </>
  );
}
export default WebEconomicas;
